export const environment = {
  production: false,
  envName: 'staging',
  PM_DASH_URL: 'https://api-staging-pmdashboard.engineer.ai/api/v1/',
  API_URL: 'https://api-store-staging.builder.ai/',
  CLOUD_API_URL: 'https://staging-api.cat.builder.ai/v1/pricing',
  LAMBDA_URL: 'https://1zeyudg9qa.execute-api.us-east-2.amazonaws.com/default/',
  PAYMENT_URL_INR: 'https://staging-payments.engineer.ai/api/v1/charges/preauth',
  CLIENT_URL: 'https://staging-bot.builder.ai/',
  PAYMENT_URL: 'https://easebuzz-payments.builder.ai/',
  BUILDER_LIVE_URL: 'https://staging-v4-home.builder.ai/',
  STORE_URL: 'staging-store.builder.ai/',
  USER_COOKIE: 'user_staging',
  ENABLE_SENTRY: true,
  FE_URL: 'https://staging.engineer.ai',
  CALENDLY_URL: 'https://staging-scheduler.builder.ai/?call_type={type}',
  SCHEDULAR_BASE_URL: 'https://api-staging-schedular.builder.ai/api/v1/',
  CAPCHASE_URL: 'https://capchase.studio.stage.eastus.az.svc.builder.ai/',
  TWOPAY_URL: 'https://two-pay.studio.stage.eastus.az.svc.builder.ai/api/',
  NATASHA_URL: 'https://ball-e-api.isg-jarvis.stage.eastus.az.svc.builder.ai/api/v1/get_images',
  NATASHA_API_KEY: 'https://ball-e-api.isg-jarvis.stage.eastus.az.svc.builder.ai/api/v1/get_images',
  NATASHA_WEB_SOCKET_URL: 'wss://studio-ai-assistant-apis.studio.stage.eastus.az.svc.builder.ai/',
  WSS_BUILDER_URL: 'wss://engineer-ai-backend-beta.studio.stage.eastus.az.svc.builder.ai/cable',
  GEO_IP_URL: "https://api.ipapi.com/api",
  SASSPAY_URL: 'https://api-stage-saas-pay.builder.ai/',
  ENTERPRISE_SURFACE: {
    FE_URL: "https://staging-enterprise.builder.ai",
    API_URL: "https://ent-staging.bhome.stage.eastus.az.svc.builder.ai/",
    USER_PLAN_COOKIE: 'user_plan_staging'
  },
  DLS_STORE_NATASHA_URL: 'wss://studio-ai-assistant-apis.studio.stage.eastus.az.svc.builder.ai/cable',
  ENCRYPTED_VALUES: {
    "PM_DASHBOARD_AUTH": "U2FsdGVkX19RR2UaVd+Sb7PrxT6lYcg0Cvt6S8zV4LxFTBZHT1tVIDYQFP2PEhnR",
    "STRIPE_PUBLISHABLE_KEY": "U2FsdGVkX1/pDls5cJ/kthIhdc4kXS9kfxpIyEdqgge4wTWv3NQJQondyxHupXvVB79CDI88YW2zXYk2weS7sw==",
    "CLOUD_AUTH_TOKEN": "U2FsdGVkX18sX2HPzkzZc9uj/FJ2nvhZ66PsW4tmz1qLrYKf8LrFn85b4aqzXBGWEWF3/tbgVm9Rlk4jJ6m0Uw==",
    "SCHEDULAR_SECRET_KEY": "U2FsdGVkX18Hty5UQFax+BrAH5Jr9y+Q90zq1pEyADQ8LkczomhFHHiLHAKKkkAL",
    "GEO_IP_ACCESS_KEY": "U2FsdGVkX1/mdiuGqTUG1nCvTRlTlg89difymeR45CWpwy+HC3Mb1gp+IPzeLa6N1DH9yEe/Tswf89SYFhBu7g==",
    "SASSPAY_IFRAME_TOKEN": "U2FsdGVkX18a2GxznjwGbmNCsNzQwX6x/AwRKWuLQ8sPWrKpIi2gfW+7plSxv6XNNsffB/paeTKQa11BK18NDw==",
    "NATASHA_REPO_APP_TOKEN": "U2FsdGVkX1+R1pJSRnr+GHcys8uwzjjc6xtElkJoiJYk61UhtxlH/ixXXIe48nbA",
    "ENTERPRISE_SECRET_KEY": "U2FsdGVkX1/ZbikIYgHyz1fk/y+vEwgd/bkQ7zywz6vYVhaUid8lS3AYvtYRXGvEhSRxO+2im/ZIBkeNVqbQuw==",
    "PAYMENT_APP_TOKEN": '9ut4ECNZXKlh5oBKclp_MA',
  }
}


