import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { BundleModel } from '@models/bundle-model/bundle-model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CurrencyModel } from '@models/currency-model/currency.model';
import { Router } from '@angular/router';
import { HomepageDataModel } from '@models/homepage-data-model/homepage-data.model';
import { UrlParameterModel } from '@models/url-parameters-model/url-parameter.model';
import { UserModel } from '@models/user-model/user.model';
import { TeamsModel } from '@models/teams-model/teams.model';
import { PromotionModel } from '@models/homepage-data-model/promotion.model';
import { CategoryModel } from '@models/category-model/category.model';
import { AppModel } from '@models/app-model/app.model';
import { environment } from '@env/environment';
import { Constants } from '@shared/utils/constants';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { BuildCardModel } from '@models/build-card-model/build-card.model';
import { PartnerDashboardDataService } from '@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service';
import { FeatureModel } from '@models/feature-model/feature.model';
import { ExperimentModel } from '@models/experiment-model/experiment.model';
import { TemplateDetailModel } from '@models/template-detail-model/template.detail.model';
import { CommonService } from '@shared/services/common.service';
import { ChangeFeatureRequestModel } from '@models/feature-model/change-feature-request.model';
import { PrototypeInstallmentModel } from '@models/prototype-installment-model/prototype-installment.model';
import { MeetNowModel } from '@models/meet-now-model/meet-now.model';
import { ExitIntentFeedbackModel } from '@models/Exit-intent-feedback-model/exit-intent-feedback-model';
import { SocialUser } from 'angularx-social-login';
import moment from 'moment';

@Injectable()
export class DataService {
  public activeChangeRequesObj: any;
  public IsGstValid :boolean;
  public openSchedulerPopup = false;
  public betaUrlForNow = 'https://beta-now.builder.ai/';
  public categories: Array<CategoryModel>;
  public apps: Array<AppModel>;
  public teams: TeamsModel;
  public featureBundles: Array<BundleModel>;
  redirectionUrl = '';
  public docuSignUrl;
  public mastercontractdocuSignUrl;
  public anonymousId = "";
  public isEnterpise = false;
  public isHireADev = false;
  public isHireDevOps = false;
  public normalFlow = false;
  public paymentStatusFlow = false;
  public resetPassword = false;
  public settings;
  public usRegion;
  public usRegionSkills;
  public asiaRegion;
  public asiaRegionSkills;
  public showCurrencyPopup = false;
  public showCurrencyAfterLogin = false;
  public user: UserModel;
  public previousCurrency;
  public unsavedChanges = false;
  public invalidAccess = false;
  public regions;
  public invite = false;
  public showLoader = false;
  public toDashBoard = false;
  public toSummary = false;
  public buildCardLink = "";
  public isBuildCardUrl = false;
  public homePageData: HomepageDataModel;
  public dashboardCards: any = { card_completed_build_card: [], card_initialized_build_card: [], running_build_card: [], archived_build_card: [], invited_build_card: [], spec_calls: [], prototypeCards: [], customPrototypeCards: [], completedProjectCards: [], demo_calls: []};
  public buildCardData: BuildCardModel;
  public modifiedBuildCardData: any;
  public completedCards = [];
  public paidCards = [];
  public inProgressCards = [];
  public invitedCards = [];
  public archivedCards = [];
  public invitedCard: any;
  public firstPage = "";
  public currentPage = "";
  public landingPage = "";
  public showNoProgressMessage = false;
  public showNoCompletedMessage = false;
  public hashPath;
  public fromPasswordReset = false;
  public shareVideo = false;
  public locale = "en-US";
  public todos;
  public shareUrlData;
  public buildCardCountData;
  public isScrolled = true;
  public loadMore = true;
  public defaultCurrency: any;
  public signupData;
  public loginData;
  public showUserConfirmationToast = false;
  public updateCustomFeature: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public allBuildCards;
  public buildCardUpdates;
  public editedTemplates = [];
  public editedFeatures = [];
  public manualPromotion: PromotionModel;
  public isAdminDashboard = false;
  public country_code = "";
  public currencyInUrl;
  public expertImages;
  public showHideViews: any = {
    welcome: false,
    configuration: false,
    home: false,
    profile: false,
    verify: false,
    confirmation: false,
    signup: false,
    partnerVerified: false,
    payment: false
  };
  public configuration: any = {
    step1: false,
    step2: false,
    step3: false
  };
  public paymentSkipped = false;
  public partnerProfileSubmitted = false;
  public isPartnerProfileUpdated = false;
  public allContractsSigned = false;
  public showUniqueCodeScreen = false;
  public emailIdToShareWith = "";
  public isEmailToBeOwner = true;
  public isBecomePartner = false;
  public isSkipDone = false;
  public specCallCount = 0;
  public isSpecCallAPiResolved = false;
  public specCards = [];
  public demoCards = [];
  public customPrototypeCards = [];
  public tailorMadePrototypeCards = [];
  public freemiumCards = [];
  public prototypeCards = [];
  public isDelivery = false;
  public isInstantPrototype = false;
  sendToProjectProgress = false;
  public loadFilteredData = false;
  public showDeliveryLoading = false;
  public isLambdaURL = false;
  public dataLoadingCurrency: CurrencyModel;
  public isUserProfileSelected = false;

  public homapageDataSubject: Subject<any> = new Subject<any>();
  public couponObject = new PromotionModel();
  public couponName = null;
  public cookie_domain = null;
  public user_cookie = null;
  public renameInProgress = false;
  public featureList: FeatureModel[] = [];
  public cardToDelete;
  public experimentPromotion: ExperimentModel = new ExperimentModel();
  public fairUsagePolicy = false;
  public pendingURL = '';
  public fetchPayConfirmData = false;
  public countryNameCode: string;
  isTradeMarkStrip = true;
  signupModalDynamicContent = [];
  signupSrcName: string;
  isSideBarVisible = false;
  isMobileModel = false;
  multiplierExperiment = {
    id: '',
    duration_multiplier: '',
    price_multiplier: '',
    custom_price_multiplier: ''
  };
  isEditBuildCard = false;
  public templateDetailModel: TemplateDetailModel;
  isFromAppDetails = false;
  public showAppDetailsLoader = false;
  public showHidePhaseBreakUp = false;
  public showPriceLoader = false;
  public removePromoLoader = false;
  public showCardPopUpAfterCurrencySetup = false;
  isIncompleteCardEditMode = false;
  additionalFeatures = [];
  additionalFeatureIds = [];
  public changeFeatureRequestModel = new ChangeFeatureRequestModel();
  public changeFeatureRequestArrayModel: ChangeFeatureRequestModel[] = [];
  public prototypeInstallmentModel = new PrototypeInstallmentModel();
  totalAdditionalFeaturesCost = 0;
  totalAdditionalFeaturesWeek = 0;
  priceWithoutCareAndSubs = 0;
  public showApproveAndContinuePopup = false;
  public activeAdditionalFeatureRequestCard = false;
  additionalFeatiresPriceWeeks = { price: 0, weeks: 0 };
  bundlesFeatures: FeatureModel[];
  totalSelectedFeatures: number;
  public isEditRequestedFeatures = false;
  public onPageReload = false;
  showWeekly = false;
  showMonthly = false;
  showUpfront = false;
  proposalInstallments = [];
  public isDirectCheckOut = false;
  public isRestrictEdit = false;
  public isFromAdditionalFeature = false;
  isChangeRequest: string;
  public changeRequestDataLoaded = false;
  public isMSAUpdatedFromPaymentPlan = false;
  public isContractAPICalled = false;
  public isFromViewPrototype = false;
  public showPrototypePayment = true;
  showExpertsButtonInHeader = false;
  showMeetNowModel = false;
  openMeetNowModal = false;
  isNoramlSpecCall = false;
  promotionData: MeetNowModel;
  showMeetNowOverloading = false;
  showMeetNowOverloadingOnFeature = false;
  openMeetNowFromAppDetail = false;
  appDetails: TemplateDetailModel;
  applyAnimationClass = false;
  currentPageName = '';
  previousPage = '';
  public exitIntentSecondScreen = false;
  public paymentExitFeedbackOptions: Array<ExitIntentFeedbackModel> = [];
  public homeExitFeedbackOptions: Array<ExitIntentFeedbackModel> = [];
  public homeExitFeedbackSubmitted = '';
  public hideExitIntentPopUp = false;
  public showFeaturesList: boolean;
  public openPlatform = false;
  public showPaymentCards = false;
  paymentCardsCount = 0;
  public meetNowScheduleCall = false;
  public meetNowConnectCall = false;
  public isAfterAuthentication = false;
  public isSpecCallCreated = false;
  public promotionList: PromotionModel[] = [];
  loginNewModule = false;
  public isScheduleCall = false;
  public completedProjectCards = [];
  public errorMessage = false;
  comingViewPrototype = false;
  public showBuilderCarePriceLoader = false;
  public socialSignUser: SocialUser;
  public showLoaderForBNow = false;
  paymentUrl: any;
  public newSignUpFlowScreen = 'signup';
  public isExpiredPromotion = false;
  public isForCcAvenue = false;
  public questionsDataForInsantSpecCall = [];
  public questionIndexForInstantSpecCall = 0;
  public showQuestionsForInstantSpec = false;
  showSettingsPopup = false;
  showPlatformSelectionPopup = false;
  natashaDropdownPanel = false;
  userCurrencyAndCountries = { available_country: [], available_currency: [] };
  public isMobileFooterStrip = false;
  public isModificationPending = false;
  isExpertClicked: string;
  showDynamicPricingModalSetting = true;
  showFeatureRemovalConfirmModalSetting = true;
  showToasterOnFeaturePage = false;
  questionMarkClicked = false;
  showDynamicPriceModal = false;
  dynamicPricingModalForSingleFeature = false;
  dynamicPricingModalForAllRecommendedFeatures = false;
  notifyTutorialComing = false;
  showFeatureTour = false;
  noMorePricingModal = true;
  public recommendedFeatureIdsAdded = [];
  dynamicPricingSetting = {
    value: false,
    isChanged: false
  };
  featureRemovalConfirmSetting = {
    value: true,
    isChanged: false
  };
  filteredFeatures: FeatureModel[];
  isShowBantModal = false;
  featureRecommendationsConfirmSetting = {
    value: true,
    isChanged: false
  };
  showFeatureRecommendationsConfirmModalSetting = true;
  natashaAvatarBackground = '#B388FF';
  natashaAvatarChatHandle = '#7C4DFF';
  natashaAvatarInitials = 'G';
  timeSpentBeforeSpecCallCreation = 0;
  isShowTemplateModal = false;
  templateSlug = '';
  searchText: string;
  specingId: number;
  isScrollTopFeatures = false;
  appPricingModal = '';
  availableDevices = [];
  remainingRequestedFeatures = [];
  isFromSummary = false;
  public isSocialSignUpForStudioStore = false;
  countryISOCode: any;
  buildCardUniqueCode: string;
  moment: any;
  updateCountryCode: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  public demoCallCount = 0;
  loggedInViaStorePage = false;
  submitAddress: BehaviorSubject<string> = new BehaviorSubject<string>('');
  expertIcon = '';
  isFromRemoveAdditionalFeature = false;
  etisalatUserData: any;
  userDataFromToken: any;
  isRedirectToHome = false;
  currencyesList = {
    USD: 'en-US',
    INR: 'en-IN',
    MYR: 'en-MY',
    EUR: 'en-EU',
    GBP: 'en-GB',
    AED: 'en-UA',
    SGD: 'SI',
    CAD: 'en-CA',
    JPY: 'ja-JP',
    SAR: 'SA'
  };
  isShowUpfrontPaymentPlan = false;
  appHeaders;
  constructor(
    public partnerDataService: PartnerDashboardDataService,
    public router: Router,
    private modelMapperService: ModelMapperService,
    private commonService: CommonService
  ) {
    // this.urlParameters = new UrlParameterModel();
    this.homePageData = new HomepageDataModel();
    this.buildCardData = new BuildCardModel();
    this.manualPromotion = new PromotionModel();
    this.categories = [];
    this.apps = [];
    this.teams = new TeamsModel();
    this.featureBundles = [];
    this.user = new UserModel();
    this.moment = moment;
  }

  public showHideLoader(showHide: boolean) {
    setTimeout(() => {
      this.showLoader = showHide;
    });
  }

  public setLanguage() {
    if (this.homePageData != null && this.homePageData.currency != null) {
      this.locale = this.currencyesList[this.homePageData.currency.code];
    }
  }

  public filterData() {
    if (this.dashboardCards.spec_calls) {
      this.specCards = this.dashboardCards.spec_calls;
    }
    if (this.dashboardCards.demo_calls) {
      this.demoCards = this.dashboardCards.demo_calls;
    }
    if (this.dashboardCards.prototype_build_card) {
      this.prototypeCards = this.dashboardCards.prototype_build_card;
    }
    if (this.dashboardCards.custom_prototype_build_card) {
      this.customPrototypeCards = this.dashboardCards.custom_prototype_build_card;
    }
    if (this.dashboardCards.tailor_made_prototype_build_card) {
      this.tailorMadePrototypeCards = this.dashboardCards.tailor_made_prototype_build_card;
    }
    if (this.dashboardCards.completed_build_card) {
      this.completedProjectCards = this.dashboardCards.completed_build_card;
    }
    if (this.dashboardCards.card_completed_build_card) {
      this.completedCards = this.dashboardCards.card_completed_build_card;
    }
    if (this.dashboardCards.freemium_build_card) {
      this.freemiumCards = this.dashboardCards.freemium_build_card;
    }

    if (this.dashboardCards.running_build_card) {
      this.paidCards = this.dashboardCards.running_build_card.filter(card => {
        return (
          card.status === Constants.buildCardStatus.STARTED ||
          card.status === Constants.buildCardStatus.UNDER_DEVELOPMENT ||
          card.status === Constants.buildCardStatus.ON_HOLD ||
          card.kick_off_date
        );
      });
    }

    if (this.dashboardCards.card_initialized_build_card) {
      this.inProgressCards = this.dashboardCards.card_initialized_build_card.filter(
        card => {
          return (
            card.status === Constants.buildCardStatus.CARD_INITIALISED &&
            !card.kick_off_date
          );
        }
      );
    }
    if (this.dashboardCards.invited_build_card) {
      this.invitedCards = this.dashboardCards.invited_build_card;
    }

    this.setBuildCardPhaseData(this.invitedCards);
    if (this.dashboardCards.archived_build_card) {
      this.archivedCards = this.dashboardCards.archived_build_card;
    }

    if (this.completedCards.length === 0) {
      this.showNoCompletedMessage = true;
    }

    if (this.inProgressCards.length === 0) {
      this.showNoProgressMessage = true;
    }
  }

  public setBuildCardPhaseData(cards) {
    cards.forEach(card => {
      card.build_phases &&
        card.build_phases.forEach(phase => {
          phase.features = [];
          phase.platforms = [];

          const buildCardPhase = card.buildcardphases.find(
            cardphase => cardphase.build_phase_id === phase.id
          );

          if (buildCardPhase) {
            buildCardPhase.feature_ids.forEach(id => {
              const feature = card.other_info.features.find(f => f.id === id);
              if (feature) {
                phase.features.push(feature);
              }
            });

            buildCardPhase.platform_ids.forEach(id => {
              const platform = card.other_info.platforms.find(p => p.id === id);
              if (platform) {
                phase.platforms.push(platform);
              }
            });
          }
        });
    });
  }

  public isEngineerAIBuilder() {
    if (this.homePageData) {
      if (this.homePageData.builder_partner) {
        return this.homePageData.builder_partner.code === "e-ai";
      }
    }
    return false;
  }

  public setupHomePageData(data: HomepageDataModel) {
    this.homePageData = this.modelMapperService.getMappedModel(this.homePageData, data);
    this.homePageData.build_phases.splice(5, 1);
    if (data && data.country_code) {
      this.country_code = data.country_code;
      this.updateCountryCode.next(true);
    }
    if (this.user && this.user.currency && !this.checkIfCPEUser()) {
      this.homePageData.currency = this.user.currency;
      this.previousCurrency = this.user.currency;
      this.defaultCurrency = this.user.currency;
    } else if (this.homePageData.currency) {
      this.previousCurrency = this.homePageData.currency;
      this.defaultCurrency = this.homePageData.currency;
    }
    if (this.homePageData.currencies && this.homePageData.currencies.length > 0) {
      if (this.user && this.user.currency) {
        this.user.currency = this.homePageData.currencies.filter(currency => {
          return currency.id === this.user.currency.id;
        })[0];
        this.storeUserObject();
        if (!this.currencyInUrl) {
          this.homePageData.currency = this.user.currency;
          this.previousCurrency = this.user.currency;
          this.defaultCurrency = this.user.currency;
        }
      }
    }
    this.availableDevices = [...new Set(this.homePageData.platforms.map(p => p.device))];
    if (this.homePageData.promotion && this.moment(this.homePageData.promotion.expires_at, 'DD-MMM-YYYY').diff(this.moment().format('DD-MMM-YYYY'), 'days') < 1) {
      this.isExpiredPromotion = true;
    }

    this.setLanguage();
  }

  public getByIndex(array, index) {
    for (const thing of array) {
      if (thing.index === index) {
        return thing;
      }
    }
  }

  public getBuildByIndex(index) {
    return this.getByIndex(this.homePageData.build_phases, index);
  }

  public getLocaledPrice(price, currency?) {
    if (price == null) {
      return "0.00";
    }
    price = String(price).replace(/,/g, "");
    const [integerPart, decimalPart] = price.split(".");
    const roundedDecimal = decimalPart ? parseFloat(`0.${decimalPart}`).toFixed(2).slice(2) : "00";
    const locale = currency ? this.currencyesList[currency.code] : this.locale;
    const formatter = new Intl.NumberFormat(locale);
    const formatedPrice = formatter.format(integerPart) + '.' + roundedDecimal;
    return formatedPrice;
  }

  public getLocaledPriceInt(price) {
    if (price != null) {
      price = String(price);
    }
    if (price != null) {
      if (price.indexOf(",") !== -1) {
        price = price.split(",").join("");
      }
      let priceSliced = [];
      if (price.indexOf(".") != -1) {
        priceSliced = price.split(".");
        price = priceSliced[0];
      }
      const formatter = new Intl.NumberFormat(this.locale);
      return formatter.format(price);
    } else {
      return "0.00";
    }
  }

  public storeUserObject() {
    this.setCookie();
  }

  public resetPartnerViews() {
    this.showHideViews = {
      welcome: false,
      configuration: false,
      home: false,
      profile: false,
      verify: false,
      confirmation: false,
      signup: false,
      partnerVerified: false,
      payment: false
    };
    this.paymentSkipped = false;
    this.partnerProfileSubmitted = false;
  }

  public resetConfigurationViews(): void {
    this.configuration = {
      step1: false,
      step2: false,
      step3: false
    };
  }

  public getCardCount(type): number {
    if (this.buildCardCountData) {
      switch (type) {
        case 'completed':
          return this.buildCardCountData['completed'];
        case 'inprogress':
          return this.buildCardCountData['incomplete'];
        case 'paid':
          return this.buildCardCountData['running'] || this.paidCards.length;
        case 'invited':
          return this.buildCardCountData['invited'];
        case 'archived':
          return this.buildCardCountData['archived'];
        case 'custom_prototype':
          return this.buildCardCountData['custom_prototype'];
        case 'prototype':
          return this.buildCardCountData['prototype'];
        case 'project_completed':
          return this.buildCardCountData['project_completed'];
        case 'freemium':
          return this.buildCardCountData['freemium'];
        case 'tailor_made_prototype':
          return this.buildCardCountData['tailor_made_prototype'];
      }
    } else {
      return 0;
    }
  }

  public unsetCookie() {
    if (this.commonService.isPlatformBrowser) {
      localStorage.removeItem(this.user_cookie)
      document.cookie = `${this.user_cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.cookie_domain};`;
    }
  }


  public setCookie() {
    this.user.frontend_url = window.location.origin;
    if (this.user.address) {
      if (this.user.address.street) {
        this.user.address.street = encodeURIComponent(this.user.address.street);
      }
      if (this.user.address.apartment) {
        this.user.address.apartment = encodeURIComponent(this.user.address.apartment);
      }
    }
    const usercookie = JSON.parse(JSON.stringify(this.user));
    if (usercookie.currency && usercookie.currency.symbol) {
      usercookie.currency.symbol = encodeURIComponent(usercookie.currency.symbol);
    }
    if (usercookie.currency && usercookie.bant_answers) {
      usercookie.bant_answers = encodeURIComponent(usercookie.bant_answers);
    }
    delete usercookie.profile_image;
    if (this.commonService.isPlatformBrowser) {
      if (usercookie.available_country) {
        delete usercookie.available_country;
      }
      localStorage.setItem(this.user_cookie, JSON.stringify(usercookie))
      document.cookie = `${this.user_cookie}=${JSON.stringify(this.getcookieData(usercookie))}; path=/; domain=${this.cookie_domain};`;
    }
  }


  getcookieData(usercookie) {
     return   {
      'id' : usercookie.id,
      'pm_dashboard_token' : usercookie.pm_dashboard_token,
      'authtoken' : usercookie.authtoken
    }
  }

  getTrademarkStripStatus() {
    if (this.commonService.isPlatformBrowser) {
      return localStorage.getItem('tradeMarkTripViewed') ? localStorage.getItem('tradeMarkTripViewed') : '';
    } else {
      return '';
    }
  }

  showModelOnMobile() {
    this.isMobileModel = !this.isMobileModel;
  }

  getExperimentMultiplierCode() {
    if (this.homePageData.multiplier_experiment && this.homePageData.multiplier_experiment.code) {
      return this.homePageData.multiplier_experiment.code;
    }
    return '';
  }

  getCurrency() {
    if (this.homePageData && this.homePageData.currency) {
      return this.homePageData.currency.symbol;
    } else if (this.user && this.user.currency) {
      return this.user.currency.symbol;
    }
  }

  checkIfCPEUser() {
    return (this.user && this.user.roles && this.user.roles === 'CPE');
  }

  public getPageName() {
    switch (this.currentPage) {
      case 'home':
        return (this.currentPageName = 'Home');
      case 'apps':
        return (this.currentPageName = 'Apps');
      case 'features':
        return (this.currentPageName = 'Features');
      case 'delivery':
        return (this.currentPageName = 'Delivery');
      case 'summary':
        return (this.currentPageName = 'BuildCard');
      case 'templateDetail':
        return (this.currentPageName = 'App Details');
      case 'paymentPlan':
        return (this.currentPageName = 'Payment Plan');
      case 'billingDetails':
        return (this.currentPageName = 'Billing Detail');
      case 'payConfirm':
        return (this.currentPageName = 'Pay Confirm');
      default:
        return '';
    }
  }

  public showHideLoaderForBNow(showHide: boolean) {
    this.showLoaderForBNow = showHide;
  }

  public isEditBuildCardAfterSecurityPaid(): boolean {
    if ( Object.keys(this.buildCardData).length < 1){
      return false;
    }
    let editRestrict = false;
    if (this.buildCardData && this.buildCardData.progress && this.buildCardData.progress.apps) {
      editRestrict = this.buildCardData.progress.apps.filter(x => x.restrict_edit === true).length > 0 ? true : false;
    }

    return (this.buildCardData ?.status === 'started' && !this.buildCardData ?.kick_off_date && !(this.buildCardData.type === 'rental_price' && this.buildCardData?.source === 'app_detail') &&
      !editRestrict);
  }

  public getOldPrice() {
    if (this.buildCardData.modification_request) {
      return this.buildCardData.new_care_flow ? this.buildCardData.modification_request.base_build_card_data.bcard_discounted_price : this.buildCardData.modification_request.base_build_card_data.discounted_price;
    } else {
      return this.buildCardData.new_care_flow ? this.buildCardData.bcard_discounted_price : this.buildCardData.discounted_price;
    }
  }

  checkIfEnterpriseUserOrCard() {
    if (this.checkIfBuildCardisinvited() && (this.buildCardData.status === 'card_initialized' || this.buildCardData.status === 'card_completed')) {
      return (this.user && this.user.enterprise_lead);
    } else if (!this.checkIfBuildCardisinvited() && (this.buildCardData.status === 'card_initialized' || this.buildCardData.status === 'card_completed')) {
      return (this.buildCardData && (this.buildCardData.enterprise_lead === 'true'));
    }
  }

  returnNeededPrototypeName() {
    const page = this.currentPage;
    let isTailorMade;
    if ((page === 'features') || (page === 'delivery')) {
      isTailorMade = this.homePageData.build_phases.find(phase => (phase.title === 'Professional Prototype')).is_tailor_made;
    } else {
      const isPrototypeSelected = this.buildCardData.progress.buildcardphases.find(phase => (phase.title === 'Professional Prototype'));
      isTailorMade = isPrototypeSelected ? isPrototypeSelected['is_tailor_made'] : isPrototypeSelected;
    }
    return isTailorMade ? 'Tailor-made Prototype' : 'Professional Prototype';
  }

  checkIfBuildCardisinvited() {
    return ((this.user && this.user.email) === this.buildCardData.owner_email);
  }

  calculateDonationAmount(percentage) {
    const buildCardData = this.buildCardData;
    buildCardData.donation_percentage = percentage;
    const price = buildCardData.price + (buildCardData.change_price_status ? buildCardData.change_amount : -buildCardData.change_amount);
    buildCardData.donation_amount = ((price * percentage) / 100);
  }

  checkIfCardWithUsd() {
    return ((this.buildCardData.currency && (this.buildCardData.currency.id === 2)) ||
      (this.homePageData.currency && (this.homePageData.currency.id === 2)));
  }

  isUnpaidCard() {
    return ((this.buildCardData.status === 'card_initialized') || (this.buildCardData.status === 'card_completed'));
  }

  getUserPriceMultiplier() {
    return this.buildCardData.user_price_multiplier ? this.buildCardData.user_price_multiplier : (this.user && this.user.price_multiplier && !this.buildCardData.id) ?  this.user.price_multiplier: null;
  }

  getRandomValueForList(arr) {
    if (Array.isArray(arr) && arr.length > 0) {
      return Math.floor(Math.random() * arr.length);
    }
    return 0;
  }

  getCookieForUTMparams(cname) {
    if (this.commonService.isPlatformBrowser) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
              c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
          }
      }
      return "";
    }
    return "";
  }
}


