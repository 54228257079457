import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { DataService } from "@shared/services/data.service";
import { PhaseService } from "@shared/services/phase.service";
import { ApiRequests } from "@shared/services/api-requests.service";
import { AppDataService } from "@rootmodule/app-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Angulartics2Segment } from "angulartics2/segment";
import { Intercom } from "ng-intercom";
import { ClipboardService } from "ngx-clipboard";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { PaymentBuilderService } from "@featuremodules/payment-builder-module/payment-builder.service";
import { SpecCallModel } from "src/app/shared/shared-module/models/spec-call-model/spec-call.model";
import { Subscription } from 'rxjs';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { BillingDetailsService } from '@shared/services/billing-details.service';
import { CommonService } from '@shared/services/common.service';
import { Constants } from '@shared/utils/constants';

@Component({
  selector: "app-meet-now",
  templateUrl: "./meet-now.component.html",
  styleUrls: ["./meet-now.component.scss"],
})
export class MeetNowComponent implements OnInit, OnDestroy {
  specingId: number;
  isFinalPriceZero = false;
  isInvalidPromo = false;
  showAvailabilityScreen = false;
  @Input() isExpertAvailableButtonClicked: boolean;
  @Input() isFromBillingDetail: boolean;
  @Input("isShowExitIntent") isShowExitIntent;
  @Output("handleClose") handleExitIntentClose = new EventEmitter();
  currentQuestion;
  buildcarsspecSubcription: Subscription;
  showThankYouScreen = false;
  minutes = 1;
  showCancelOptions = false;
  seconds = 30;
  isExpertReadyForCall = false;
  innerWidth: number;
  innerHeight: number;
  showContinueWithQuesOption = true;
  discountCouponCode: string;
  zoomWindowLeftCoordinate: number;
  zoomWindowTopCoordinate: number;
  specCallType: string;
  callType: any;
  schedulerURL: SafeResourceUrl;
  bant_anwsers = {};
  bantQuestion: any[];
  firstTimeTohitApiforSpeccall: any;
  public carouselTile: NguCarouselConfig;
  public carouselTileItems: Array<any> = [];
  constructor(
    public dataService: DataService,
    public phaseService: PhaseService,
    private apiRequest: ApiRequests,
    public billingService: BillingDetailsService,
    public appDataService: AppDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public analyticsSegment: Angulartics2Segment,
    private _clipboardService: ClipboardService,
    public sanitizer: DomSanitizer,
    public paymentService: PaymentBuilderService
  ) {
    this.innerWidth = 0.5 * window.innerWidth;
    this.innerHeight = 0.5 * window.innerHeight;
    this.zoomWindowLeftCoordinate = (window.innerWidth - this.innerWidth) / 4;
    this.zoomWindowTopCoordinate = (window.innerHeight - this.innerHeight) / 2;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }

  ngOnInit() {
    this.carouselTileItems = this.dataService.expertImages;
    if (this.appDataService.urlParameters.schedular_reason) {
      //for  marking url like ?reason=book-demo
      this.dataService.openSchedulerPopup = true;
      const scheduleType = this.appDataService.urlParameters.schedular_reason;
      let call_type =
        scheduleType == "book-demo"
          ? "demo"
          : scheduleType == "book-spec"
            ? "spec"
            : "kickoff";
      this.schedulerURL = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.phaseService.showTimeKitWindow(
          false,
          this.dataService.specingId,
          call_type
        )
      );
      this.appDataService.urlParameters.schedular_reason = "";
      return;
    }
    if (this.phaseService.checkIfSelfServeUser()) {
      this.getBantQuestionForSelfServe();
    } else {
      this.getBantQuestion();
    }
    if (
      this.dataService.currentPage === "paymentStatus" ||
      this.dataService.currentPage === "dashboard" ||
      this.phaseService.isFromDashboard
    ) {
      this.dataService.isShowBantModal = false;
      if (this.phaseService.showScheduleCallFlow) {
        if (this.dataService.user) {
          this.dataService.openSchedulerPopup = true;
          this.dataService.specingId = this.dataService.buildCardData.generic_uuid
            ? this.dataService.buildCardData.generic_uuid
            : this.dataService.specingId;
          this.schedulerURL = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.phaseService.showTimeKitWindow(
              false,
              this.dataService.buildCardData.generic_uuid,
              "kickoff"
            )
          );
        } else {
          this.phaseService.showSignNDAPopup = true;
        }
      }
      // else {
      //   this.connectNowClicked(false, 'normal');
      // }
    } else if (this.dataService.user?.bant_answers?.length) {
      this.dataService.isShowBantModal = false;
      if (this.phaseService.showInstantSpecCallFlow) {
        this.connectNowClicked(false, "instant");
      }
      // if (this.phaseService.showScheduleCallFlow) {
      //   if ((this.dataService.currentPage) === 'paymentStatus' || (this.dataService.currentPage === 'dashboard') || (this.dataService.currentPage === 'paymentPlan') || this.phaseService.isFromDashboard) {
      //     //for kickoff project from dashboard
      //     this.phaseService.isFromDashboard = true;
      //     this.dataService.openSchedulerPopup = true;
      //     this.dataService.specingId = this.dataService.buildCardData.id ? this.dataService.buildCardData.id : this.dataService.specingId;
      //     this.schedulerURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.phaseService.showTimeKitWindow(false, this.dataService.specingId, 'kickoff'));
      //   }
      //   else {
      //     this.connectNowClicked(false, 'normal');
      //   }
      // }
    } else if (this.appDataService.urlParameters.calltype) {
      this.dataService.isShowBantModal = false;
    } else {
      this.dataService.isShowBantModal = true;
    }
    this.dataService.isSpecCallCreated = false;
    this.phaseService.isPaymentDone = false;
    if (
      !this.dataService.isAfterAuthentication &&
      !this.phaseService.showInstantSpecCallFlow &&
      !this.phaseService.showScheduleCallFlow
    ) {
      this.phaseService.showMeetNowSearchingScreen = false;
    }
    if (this.appDataService.urlParameters.specingId) {
      this.specingId = parseInt(this.appDataService.urlParameters.specingId);
      this.phaseService.handleSpecingStatus(this.specingId);
    }
    this.apiRequest.fetchPercentagePromotionList().subscribe((data) => {
      this.discountCouponCode = this.dataService.promotionList[0].code;
    });

    this.carouselTile = {
      grid: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, all: 0 },
      slide: 1,
      speed: 250,
      loop: true,
      interval: {
        timing: 2000,
      },
      point: {
        visible: true,
      },
      load: 2,
      velocity: 0,
      touch: true,
      easing: "cubic-bezier(0, 0, 0.2, 1)",
    };
  }

  public connectNowEvents(
    isForPayment?,
    specType?,
    callType?,
    isFromExpertNotAvailableScreen?
  ) {
    if (!isForPayment && specType === "instant" && callType === "demo") {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "demo_call_time_confirms",
        {
          user_id: this.dataService.user?.id,
          Screen_source: this.dataService.currentPage,
        }
      );
    }
    if (!isForPayment && specType === "instant" && !callType) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "spec_call_time_confirms",
        {
          user_id: this.dataService.user?.id,
          Screen_source: this.dataService.currentPage,
        }
      );
    }
    if (isForPayment && specType === "normal" && !callType) {
      if (isFromExpertNotAvailableScreen) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "spec_now_schedule_a_call_clicked",
          {
            user_id: this.dataService.user?.id,
            Screen_source: this.dataService.currentPage,
            page_source: "Spoc Unavailable",
          }
        );
      } else {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "spec_now_schedule_a_call_clicked",
          {
            user_id: this.dataService.user?.id,
            Screen_source: this.dataService.currentPage,
            page_source: "Primary",
          }
        );
      }
    }
    if (isForPayment && specType === "normal" && callType === "demo") {
      if (isFromExpertNotAvailableScreen) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "demo_now_schedule_a_call_clicked",
          {
            user_id: this.dataService.user?.id,
            Screen_source: this.dataService.currentPage,
            page_source: "Spoc Unavailable",
          }
        );
      } else {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "demo_now_schedule_a_call_clicked",
          {
            user_id: this.dataService.user?.id,
            Screen_source: this.dataService.currentPage,
            page_source: "Primary",
          }
        );
      }
    }
  }

  connectNowClicked(
    isForPayment?,
    specType?,
    callType?,
    isFromExpertNotAvailableScreen?
  ) {
    this.connectNowEvents(
      isForPayment,
      specType,
      callType,
      isFromExpertNotAvailableScreen
    );
    this.dataService.unsavedChanges = false;
    this.isInvalidPromo = false;
    this.specCallType = specType;
    this.callType = callType;
    if (
      !(
        this.dataService.promotionData &&
        this.dataService.promotionData.spec_call_data.payable_amount
      ) &&
      isForPayment &&
      specType === "instant"
    ) {
      this.phaseService.isPaymentDone = true;
      this.isFinalPriceZero = true;
      this.updateSpecCallToPaid();
      return;
    }
    if (isForPayment) {
      if (this.appDataService.urlParameters.red === "ip_now") {
        this.appDataService.urlParameters.red = "";
      }
      if (specType === "normal") {
        if (!this.dataService.user) {
          this.dataService.meetNowScheduleCall = true;
          // this.dataService.exitIntentSecondScreen = false;
          this.checkAuthentication();
        } else {
          this.dataService.showHideLoader(true);
          this.dataService.isScheduleCall = true;
          this.createNormallOrInstantSpecCall(specType, callType);
        }
      } else {
        this.phaseService.handleSpecingStatus(this.specingId);
      }
    } else {
      if (!this.dataService.user) {
        this.dataService.meetNowConnectCall = true;
        this.dataService.isSpecCallCreated = true;
        this.phaseService.showMeetNowSearchingScreen = true;
        this.checkAuthentication();
      } else {
        this.phaseService.showMeetNowSearchingScreen = true;
        this.createNormallOrInstantSpecCall(specType, callType);
      }
    }
  }

  checkToShowNDA(isFromExpertNotAvailableScreen?) {
    if (this.dataService?.user?.spec_call_nda_signed) {
      this.connectNowClicked(
        true,
        "normal",
        null,
        isFromExpertNotAvailableScreen
      );
    } else {
      this.phaseService.showSignNDAPopup = true;
    }
  }

  public checkAuthentication() {
    this.phaseService.openCommonAuth = true;
    if (this.dataService.homePageData?.block_sign_up) {
      this.phaseService.showSignin = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
  }

  createNormallOrInstantSpecCall(specType, callType?) {
    if (
      this.dataService.buildCardData &&
      !this.dataService.buildCardData.id &&
      !this.phaseService.buildCardCreationStarted
    ) {
      this.createCardBeforeBookingCall(specType, callType);
    } else {
      this.createSpecCall(specType, callType);
    }
  }

  createSpecCall(specType, callType?) {
    let feedback = "";
    if (
      this.dataService.homeExitFeedbackSubmitted &&
      this.dataService.homeExitFeedbackSubmitted.length > 0
    ) {
      feedback = this.dataService.homeExitFeedbackSubmitted;
    }
    if (!this.phaseService.specCallCreationData?.id) {
      this.apiRequest
        .createSpecCall(
          specType,
          this.dataService.currentPage,
          feedback,
          callType
        )
        .subscribe(
          (resp) => {
            this.phaseService.specCallCreationData = resp;
            if (
              !this.phaseService.specCallCreationData.manager_availability &&
              specType == "instant"
            ) {
              if (callType === "demo") {
                this.phaseService.trackEventForObject(
                  this.analyticsSegment,
                  "demo_call_spoc_availability",
                  {
                    user_id: this.dataService.user?.id,
                    Screen_source: this.dataService.currentPage,
                    spoc_avaliable: "No",
                  }
                );
              } else {
                this.phaseService.trackEventForObject(
                  this.analyticsSegment,
                  "spec_call_spoc_availability",
                  {
                    user_id: this.dataService.user?.id,
                    Screen_source: this.dataService.currentPage,
                    spoc_avaliable: "No",
                  }
                );
              }
            }
            this.afterSpecCall(specType, callType);
          },
          (error) => {
            this.dataService.showHideLoader(false);
            this.dataService.isScheduleCall = false;
          }
        );
    } else {
      this.afterSpecCall(specType, callType);
    }
  }

  afterSpecCall(specType, callType?) {
    this.specingId = this.phaseService.specCallCreationData.id;
    if (specType === "instant") {
      this.dataService.isSpecCallCreated = true;
      if (this.phaseService.specCallCreationData.manager_availability) {
        this.phaseService.handleSpecingStatus(this.specingId);
        localStorage.setItem("timeSpentOnSite", "0");
        if (callType === "demo") {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            "demo_call_spoc_availability",
            {
              user_id: this.dataService.user?.id,
              Screen_source: this.dataService.currentPage,
              spoc_avaliable: "yes",
            }
          );
        } else {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            "spec_call_spoc_availability",
            {
              user_id: this.dataService.user?.id,
              Screen_source: this.dataService.currentPage,
              spoc_avaliable: "yes",
            }
          );
        }
      }
    } else if (specType === "normal") {
      localStorage.setItem("timeSpentOnSite", "0");
      this.closeQuestionPopup(false);
    }
    this.dataService.homeExitFeedbackSubmitted = "";
    this.dataService.showHideLoader(false);
    this.dataService.isScheduleCall = false;
  }

  updateSpecCallToPaid(specType?) {
    this.apiRequest
      .updateSpecCallForZeroAmount(this.specingId)
      .subscribe((resp) => {
        if (specType === "normal") {
          this.phaseService.showTimeKitWindow(false, this.specingId);
        } else {
          this.phaseService.handleSpecingStatus(this.specingId);
        }
      });
  }

  createCardBeforeBookingCall(specType, callType?) {
    this.phaseService.checkAndSetDefaultPhases();
    this.phaseService.checkTeamAndSpeed();
    this.phaseService.createBuildCardPriceHash();
    const templateDetail = this.dataService.templateDetailModel;
    if ((this.dataService.currentPage === 'templateDetail') && templateDetail && (templateDetail['pricing_modal'] === 'rental_price')) {
      this.phaseService.selectedHash.status = Constants.buildCardStatus.CARD_COMPLETED;
    }
    delete this.phaseService.selectedHash.care_type;
    this.phaseService.selectedHash.is_under_support = false;
    if (
      !this.phaseService.inProgressBuildCardId &&
      this.appDataService.urlParameters.is_freemium
    ) {
      this.phaseService.selectedHash.project_name = (
        this.dataService.appDetails.title +
        "_" +
        this.dataService.user.first_name
      ).replace(" ", "_");
      this.phaseService.selectedHash.skip_freemium_validation = true;
    }
    this.apiRequest
      .createUpdateBuildCard(
        this.phaseService.selectedHash,
        this.phaseService.inProgressBuildCardId,
        false,
        "afterLogin"
      )
      .subscribe(
        (data: any) => {
          this.phaseService.specCallCreationData = new SpecCallModel();
          if (!this.phaseService.inProgressBuildCardId) {
            this.phaseService.isNewBuildCard = true;
          }
          this.phaseService.inProgressBuildCardId =
            this.dataService.buildCardData.id;
          this.dataService.unsavedChanges = false;
          this.dataService.isFromViewPrototype = false;
          this.phaseService.buildCardEditMode = false;
          this.dataService.isEditBuildCard = false;
          this.phaseService.inProgressFlow = false;
          this.phaseService.isBuildCardSetup = false;
          // if ( this.dataService.currentPage !== 'paymentStatus' ) {
          this.createSpecCall(specType, callType);
          // }
          this.dataService.showHideLoader(false);
        },
        (error) => {
          if (error.status === 403 || error.status === 401) {
            this.dataService.user = null;
            // remove cookie here
            this.dataService.unsetCookie();

            this.phaseService.clearLocalStorage();
            this.phaseService.showSignin = true;
            this.phaseService.openCommonAuth = true;
          }
        }
      );
  }

  closeModal(showOverLoadingScreen?) {
    // this.mayBeLaterClickedEvent(showOverLoadingScreen);
    this.dataService.isRedirectToHome = false;
    const red = this.appDataService.urlParameters.red;
    if (showOverLoadingScreen !== "copyLink") {
      this.phaseService.getHelpClicked = true;
      this.dataService.applyAnimationClass = true;
      setTimeout(() => {
        this.dataService.showMeetNowModel = false;
        this.dataService.applyAnimationClass = false;
      }, 1000);
    }

    if (showOverLoadingScreen !== "copyLink") {
      if (
        (red === "meet_now" || red === "ip_now") &&
        this.dataService.currentPage === "apps"
      ) {
        this.dataService.showMeetNowOverloading = true;
      }
      if (red === "ip_now") {
        this.appDataService.urlParameters.red = "";
      }
      if (this.phaseService.meetNowForRentalClicked) {
        this.phaseService.meetNowForRentalClicked = false;
      }
      if (this.phaseService.talkToSalesClicked) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "demo_call_modal_closes",
          {
            user_id: this.dataService.user?.id,
            screen_source: this.dataService.currentPage,
          }
        );
        this.phaseService.talkToSalesClicked = false;
      }
      if (this.dataService.exitIntentSecondScreen) {
        this.dataService.hideExitIntentPopUp = true;
        this.closeEventTriggered();
      }
      this.dataService.showMeetNowModel = false;
      this.phaseService.showSignNDAPopup = false;
      if (this.phaseService.bookSpecCallClicked) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "spec_call_modal_closed",
          {
            user_id: this.dataService.user?.id,
            screen_source: this.dataService.currentPage,
          }
        );
        this.phaseService.bookSpecCallClicked = false;
      }
    }
    this.phaseService.showInstantSpecCallFlow = false;
    this.phaseService.showScheduleCallFlow = false;
    if (showOverLoadingScreen === "copyLink") {
      if (this.phaseService.talkToSalesClicked) {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "demo_call_start_now_clicked",
          {
            user_id: this.dataService.user?.id,
            Screen_source: this.dataService.currentPage,
          }
        );
      } else {
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "spec_call_start_now_clicked",
          {
            user_id: this.dataService.user?.id,
            Screen_source: this.dataService.currentPage,
          }
        );
      }
      this.showZoomWindow();
      this.dataService.showMeetNowModel = false;
      this.phaseService.showInstantSpecCallFlow = false;
    }
  }

  askForAvailability() {
    this.showAvailabilityScreen = true;
    if (this.phaseService.talkToSalesClicked) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "demo_call_connect_now_clicked",
        {
          user_id: this.dataService.user?.id,
          Screen_source: this.dataService.currentPage,
        }
      );
    } else {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "spec_call_connect_now_clicked",
        {
          user_id: this.dataService.user?.id,
          Screen_source: this.dataService.currentPage,
        }
      );
    }
  }

  ngOnDestroy() {
    this.phaseService.isFromDashboard = false;
  }

  showExitFeedback() {
    this.phaseService.showMeetNowSearchingScreen = false;
    this.phaseService.specCallCreationData = null;
    this.dataService.isSpecCallCreated = false;
    this.phaseService.isShowPaymentExitFeedback = true;
  }

  public closeEventTriggered() {
    this.handleExitIntentClose.emit();
  }

  public closeExitPopup(isFromCallScreen?, isFromSignNDA?) {
    if (this.dataService.exitIntentSecondScreen) {
      this.dataService.hideExitIntentPopUp = true;
      this.phaseService.showSignNDAPopup = false;
      this.closeEventTriggered();
    }
    this.dataService.showMeetNowModel = false;
  }

  public closePaymentExitPopups() {
    this.phaseService.closeExitIntentPopups();
  }

  showZoomWindow() {
    this.popupWindow(
      this.phaseService.specCallData.zoom_link,
      "Zoom Call",
      window,
      this.innerWidth,
      this.innerHeight
    );
  }

  decrementTimerForInstantSpecQuestions(obj: any) {
    let oldRef = obj.refVal;
    if (obj.minutes === 0 && obj.seconds === 0) {
      oldRef.isExpertReadyForCall = true;
      if (
        oldRef.dataService.questionIndexForInstantSpecCall >=
        oldRef.dataService.questionsDataForInsantSpecCall.length
      ) {
        oldRef.showContinueWithQuesOption = false;
      }
    } else {
      oldRef.decrementTimer(obj);
    }
  }

  decrementTimerForZoomCall(obj: any) {
    let oldRef = obj.refVal;
    if (obj.minutes === 0 && obj.seconds === 0) {
      oldRef.showThankYouScreen = false;
      oldRef.dataService.showQuestionsForInstantSpec = false;
      // oldRef.shouldShowSignNDAScreen(true);
      oldRef.showZoomWindow();
    } else {
      oldRef.decrementTimer(obj);
    }
  }

  decrementTimer(obj: any) {
    if (obj.seconds === 0) {
      if (obj.minutes > 0) {
        obj.seconds = 59;
        obj.minutes--;
      }
    } else {
      obj.seconds--;
    }
  }

  closeQuestionPopup(isCloseClicked) {
    this.showThankYouScreen = false;
    this.dataService.showQuestionsForInstantSpec = false;
    this.currentQuestion = null;
    this.dataService.questionIndexForInstantSpecCall = 0;
    // this.shouldShowSignNDAScreen(true);
    if (this.specCallType === "instant") {
      this.showZoomWindow();
    } else if (this.specCallType === "normal") {
      this.dataService.openSchedulerPopup = true;
      this.schedulerURL = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.phaseService.showTimeKitWindow(
          false,
          this.specingId,
          this.callType
        )
      );
    }
  }

  shouldShowSignNDAScreen(isShowSignNDA) {
    if (isShowSignNDA) {
      this.phaseService.showSignNDAPopup = true;
    } else {
      this.phaseService.showSignNDAPopup = false;
    }
  }

  closeBantModal() {
    if (this.dataService.user && !this.dataService.user?.bant_answers?.length) {
      this.dataService.showMeetNowModel = false;
    }
    this.dataService.isShowBantModal = false;
    if (this.phaseService.showSpinner) {
      setTimeout(() => {
        this.router.navigate([
          this.dataService.buildCardData.uniq_code + "/feature",
        ]);
      }, 5000);
    }
  }

  saveBantAnswer() {
    const bant_anwsers = Object.keys(this.bant_anwsers);
    if (bant_anwsers.length) {
      const postData = {
        bant_answers: [],
      };
      bant_anwsers.forEach((key) => {
        let answer = this.bantQuestion[key].options[this.bant_anwsers[key]]['value']
          ? this.bantQuestion[key].options[this.bant_anwsers[key]]
          : this.bantQuestion[key].options[this.bant_anwsers[key]]['key'];

        if (this.phaseService.checkIfSelfServeUser()) {
          if (answer === 'C-Level') {
            answer = 'CEO';
          }
          // if (answer && answer['mapToSF']) {
          //   answer['value'] = answer['mapToSF'];
          //   delete answer['mapToSF'];
          // }
        }
        // else if (answer && answer['mapToSF']) {
        //   delete answer['mapToSF'];
        // }
        postData.bant_answers.push([this.bantQuestion[key].title, answer]);
      });

      if (!this.bantQuestion.find((a) => a.title === "Size of your company?")) {
        postData.bant_answers.splice(1, 0, ["Size of your company?", "1"]);
      }
      let sizes = ["1,000+ employees"];
      if (this.phaseService.checkIfSelfServeUser()) {
        sizes = ["1001-5000 employees"];
      }
      const sizeOfCompany = postData["bant_answers"].find(
        (a) => a[0] === "Size of your company?"
      );
      if (sizes.includes(sizeOfCompany[1])) {
        postData["enterprise_lead"] = true;
      }
      this.apiRequest.saveBantAnswer(postData).subscribe((data) => {
        this.dataService.isShowBantModal = false;
        if (this.phaseService.showSpinner) {
          this.router.navigate([
            this.dataService.buildCardData.uniq_code + "/feature",
          ]);
        }
        this.apiRequest.setUpUserData(data);
        if (this.dataService.currentPage !== "templateDetail") {
          this.fetchHomePageData();
        }
        if (this.phaseService.showInstantSpecCallFlow) {
          this.connectNowClicked(false, "instant");
        } else if (this.phaseService.showScheduleCallFlow) {
          if (
            this.dataService.user &&
            this.dataService.user.spec_call_nda_signed
          ) {
            this.connectNowClicked(true, "normal");
          } else {
            this.phaseService.showSignNDAPopup = true;
          }
        } else if (
          !this.dataService.openMeetNowModal ||
          this.dataService.checkIfCPEUser()
        ) {
          this.dataService.showMeetNowModel = false;
        }
      });
    } else {
      this.closeQuestionPopup(false);
    }
  }

  fetchHomePageData() {
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.dataService.dataLoadingCurrency = Object.assign(
        {},
        this.dataService.homePageData.currency
      );
      this.phaseService.platformPrices = Array.apply(
        null,
        Array(this.dataService.homePageData.platforms.length)
      );
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price =
          this.dataService.homePageData.currency.specing_price;
      }
    });
  }

  isAllQuestionAnswered() {
    return Object.keys(this.bant_anwsers).length === this.bantQuestion.length
      ? false
      : true;
  }

  selectBantAnswer(event, questionIndex) {
    event.stopPropagation();
    this.bant_anwsers[questionIndex] = event.target.value;
    if (this.bant_anwsers[0] == 0 || this.bant_anwsers[0] == 1) {
      if (this.bantQuestion.find((a) => a.title === 'Size of your company?')) {
        const index = this.bantQuestion.indexOf(
          this.bantQuestion.find((a) => a.title === 'Size of your company?')
        );
        this.bantQuestion.splice(index, 1);
      }
    } else if (!(this.bantQuestion.find(a => a.title === 'Size of your company?')) && !this.phaseService.checkIfSelfServeUser()) {
      this.bantQuestion.splice(1, 0, {
        'title': 'Size of your company?',
        'options': [{ key: '1 to 10 people' },
        { key: '10 to 50 people' },
        { key: '50 to 250 people' },
        { key: '250 to 1,000 people' },
        { key: '1,000+ people' },
        { key: 'Not Applicable' }]
      })
    } else if (!(this.bantQuestion.find(a => a.title === 'Size of your company?')) && this.phaseService.checkIfSelfServeUser()) {
      this.bantQuestion.splice(1, 0, {
        'title': 'Size of your company?',
        'options': [{ key: '1-10 employees' },
        { key: '11-50 employees' },
        { key: '51-200 employees' },
        { key: '201-500 employees' },
        { key: '501-1000 employees' },
        { key: '1001-5000 employees' }]
      })
    }
  }

  closeSchedulerPopup() {
    this.getSpeccallData();
    this.dataService.openSchedulerPopup = false;
    this.dataService.showMeetNowModel = false;
    this.phaseService.showScheduleCallFlow = false;
    this.phaseService.specCallPopupAppeared = true;
    this.phaseService.isFromDashBard = false;
    // if (this.paymentService.isCardPaid) {
    //   this.phaseService.startPollingForSpecCall();
    // }
    if (this.dataService.currentPage === "paymentStatus") {
      this.router.navigate(["dashboard"]);
      if (this.goToTaxDetailsSection()) {
        this.phaseService.showTaxAndBilling = true;
      }
    }
  }

  checkForhomePageRedirection() {
    if (this.dataService.currentPage === "features" && this.dataService.isRedirectToHome) {
      this.dataService.isRedirectToHome = false;
      this.router.navigate(["home"]);
      this.phaseService.reset()
    }
  }
  getSpeccallData() {
    if (this.phaseService.bookSpecCallClicked) {
      if (this.buildcarsspecSubcription)
        this.buildcarsspecSubcription.unsubscribe();
      this.firstTimeTohitApiforSpeccall = new Date().getTime() + 30000;
      this.getBuildspeccalldata();
    }
  }

  getBuildspeccalldata() {
    this.buildcarsspecSubcription = this.apiRequest
      .fetchBuildcardSpecCallData()
      .subscribe((data) => {
        if (!data.spec_call.time) {
          const d = new Date().getTime();
          if (this.firstTimeTohitApiforSpeccall >= d) {
            this.getBuildspeccalldata();
          }
        } else {
          this.dataService.buildCardData["spec_call_scheduled"] =
            data.spec_call;
        }
      });
  }

  getBantQuestion() {
    this.bantQuestion = [
      {
        title: "What best describes you?",
        options: [
          { key: "Student" },
          { key: "Solo entrepreneur" },
          { key: "Manager" },
          { key: "Director" },
          { key: "CEO, CFO, CTO..." },
          { key: "Other" },
        ],
      },
      {
        title: "Size of your company?",
        options: [
          { key: "1 to 10 people" },
          { key: "10 to 50 people" },
          { key: "50 to 250 people" },
          { key: "250 to 1,000 people" },
          { key: "1,000+ people" },
          { key: "Not Applicable" },
        ],
      },
      {
        title: "When do you need your software delivered?",
        options: [
          { key: "Less than 2 months" },
          { key: "2 to 6 months" },
          { key: "6 to 12 months" },
          { key: "12 to 24 months" },
          { key: "24 months +" },
          { key: "Not sure" },
        ],
      },
      {
        title: "How much do you expect to spend?",
        options: [],
      },
      {
        title: "Which app category would it fit into?",
        options: [
          { key: 'Business & finance' },
          { key: 'Education' },
          { key: 'Entertainment & music' },
          { key: 'Food & drink' },
          { key: 'Health & fitness' },
          { key: 'Lifestyle' },
          { key: 'Medical' },
          { key: 'Navigation' },
          { key: 'News' },
          { key: 'Photo & video' },
          { key: 'Productivity' },
          { key: 'Shopping' },
          { key: 'Social Media' },
          { key: 'Travel' },
          { key: 'Utilities' },
          { key: 'Events' },
          { key: 'Sports' },
          { key: 'Other' }
        ],
      },
    ];

    this.getCurrencyOptions();
  }

  getCurrencyOptions() {
    const currencyWiseOptions = {
      INR: [
        { key: "Less than ₹400,000", value: "Less than $5,000" },
        { key: "₹400,000 to ₹2,000,000", value: "$5,000 to $25,000" },
        { key: "₹2,000,000 to ₹4,000,000", value: "$25,000 to $50,000" },
        { key: "₹4,000,000+", value: "$50,0000+" },
      ],

      USD: [
        { key: "Less than $5,000", value: "Less than $5,000" },
        { key: "$5,000 to $25,000", value: "$5,000 to $25,000" },
        { key: "$25,000 to $50,000", value: "$25,000 to $50,000" },
        { key: "$50,000+", value: "$50,0000+" },
      ],

      EUR: [
        { key: "Less than €5,000", value: "Less than $5,000" },
        { key: "€5,000 to €20,000", value: "$5,000 to $25,000" },
        { key: "€20,000 to €40,000", value: "$25,000 to $50,000" },
        { key: "€40,000+", value: "$50,0000+" },
      ],

      SAR: [
        { key: "Less than SAR 20,000", value: "Less than $5,000" },
        { key: "SAR 20,000 to SAR 100,000", value: "$5,000 to $25,000" },
        { key: "SAR 100,000 to SAR 200,000", value: "$25,000 to $50,000" },
        { key: "SAR 200,000 +", value: "$50,0000+" },
      ],

      JPY: [
        { key: "Less than ¥500,000", value: "Less than $5,000" },
        { key: "¥500,000 - ¥2,500,000", value: "$5,000 to $25,000" },
        { key: "¥2,500,000 - ¥5,000,000", value: "$25,000 to $50,000" },
        { key: "¥5,000,000+", value: "$50,0000+" },
      ],

      MYR: [
        { key: "Less than RM 20,000", value: "Less than $5,000" },
        { key: "RM 20,000 to 100,000", value: "$5,000 to $25,000" },
        { key: "RM 100,000 to RM 200,000", value: "$25,000 to $50,000" },
        { key: "RM 200,000+", value: "$50,0000+" },
      ],

      CAD: [
        { key: "Less than C$6000", value: "Less than $5,000" },
        { key: "C$6000 to C$35,000", value: "$5,000 to $25,000" },
        { key: "C$35,000 to C$70,000", value: "$25,000 to $50,000" },
        { key: "C$70,000+", value: "$50,0000+" },
      ],

      AED: [
        { key: "Less than AED 18,000", value: "Less than $5,000" },
        { key: "AED 18,000 to AED 90,000", value: "$5,000 to $25,000" },
        { key: "AED 90,000 to AED 180,000", value: "$25,000 to $50,000" },
        { key: "AED 180,000+", value: "$50,0000+" },
      ],

      SGD: [
        { key: "Less than S$6500", value: "Less than $5,000" },
        { key: "S$6500 to S$30,000", value: "$5,000 to $25,000" },
        { key: "S$30,000 to S$70,000", value: "$25,000 to $50,000" },
        { key: "S$70,0000+", value: "$50,0000+" },
      ],

      GBP: [
        { key: "Less than £5,000", value: "Less than $5,000" },
        { key: "£5,000 to £20,000", value: "$5,000 to $25,000" },
        { key: "£20,000 to £40,000", value: "$25,000 to $50,000" },
        { key: "£40,000+", value: "$50,0000+" },
      ],
    };
    this.bantQuestion[3].options =
      currencyWiseOptions[this.dataService.user.currency.code];
  }

  popupWindow(url, title, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      title,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  }

  closeSpecModal() {
    this.phaseService.showBeforeLeavingSchedulerPopup = false;
  }

  scheduleCallNow() {
    this.closeSpecModal();
  }

  scheduleCallLater(cancelReason?) {
    if (cancelReason) {
      this.dataService.showHideLoader(true);
      let reason = { kickoff_cancel_reason: cancelReason };
      const buildCardId = this.dataService.buildCardData.generic_uuid
        ? this.dataService.buildCardData.generic_uuid
        : this.dataService.specingId;
      this.apiRequest
        .kickOffCancelReason(reason, buildCardId)
        .subscribe((data: any) => {
          this.closeSpecModal();
          this.closeSchedulerPopup();
          this.dataService.showHideLoader(false);
        });
    } else {
      this.closeSpecModal();
      this.closeSchedulerPopup();
    }
  }

  showOptions() {
    this.showCancelOptions = !this.showCancelOptions;
  }

  openBeforeLeavingSchedulerPopup() {
    let callType = "";
    this.dataService.showHideLoader(true);
    if (
      this.dataService.currentPage === "paymentStatus" ||
      this.phaseService.isFromDashboard
    ) {
      callType = "kickoff";
    } else {
      callType = this.phaseService.isDemoCall ? "demo" : "spec";
    }
    let specId =
      this.phaseService.specCallCreationData &&
        this.phaseService.specCallCreationData.id
        ? this.phaseService.specCallCreationData.id
        : this.dataService.specingId
          ? this.dataService.specingId
          : null;
    this.apiRequest.confirmSpecCallBooked(callType, specId).subscribe(
      (resp) => {
        this.dataService.showHideLoader(false);
        if (resp.message !== "success") {
          this.phaseService.showBeforeLeavingSchedulerPopup = true;
        } else {
          this.closeSchedulerPopup();
          this.checkForhomePageRedirection();
        }
      },
      (error) => {
        this.dataService.showHideLoader(false);
        this.phaseService.showBeforeLeavingSchedulerPopup = true;
      }
    );
  }

  goToTaxDetailsSection() {
    const user = this.dataService.user;
    return (
      this.dataService.currentPage === "paymentStatus" &&
      (this.showTextForINR() || user.address.billed_as === "company")
    );
  }

  showTextForINR() {
    const user = this.dataService.user;
    return (
      user.currency.code === "INR" &&
      user.address.billed_as === "individual" &&
      this.dataService.currentPage === "paymentStatus"
    );
  }

  showTextExceptINR() {
    const user = this.dataService.user;
    return (
      user.address.billed_as === "company" &&
      this.dataService.currentPage === "paymentStatus"
    );
  }

  getBantQuestionForSelfServe() {
    this.bantQuestion = [
      {
        'title': 'What best describes you?',
        'options': [
          { key: 'Student' },
          { key: 'Solo entrepreneur' },
          { key: 'Manager' },
          { key: 'Director' },
          { key: 'C-Level' },
          { key: 'Other' }
        ]
      },
      {
        'title': 'Size of your company?',
        'options': [{ key: '1-10 employees' },
        { key: '11-50 employees' },
        { key: '51-200 employees' },
        { key: '201-500 employees' },
        { key: '501-1000 employees' },
        { key: '1001-5000 employees' }]
      },
      {
        'title': 'When do you need your software delivered?',
        'options': [{ key: 'Less than 2 months' },
        { key: '2 to 6 months' },
        { key: '6 to 12 months' },
        { key: '12 to 24 months' },
        { key: '24 months +' }]
      },
      {
        'title': 'How much do you expect to spend?',
        'options': []
      },
      {
        'title': 'Which app category would it fit into?',
        'options': [
          { key: 'Business & finance' },
          { key: 'Education' },
          { key: 'Entertainment & music' },
          { key: 'Food & drink' },
          { key: 'Health & fitness' },
          { key: 'Lifestyle' },
          { key: 'Medical' },
          { key: 'Navigation' },
          { key: 'News' },
          { key: 'Photo & video' },
          { key: 'Productivity' },
          { key: 'Shopping' },
          { key: 'Social Media' },
          { key: 'Travel' },
          { key: 'Utilities' },
          { key: 'Events' },
          { key: 'Sports' },
          { key: 'Other' }
        ]
      }
    ];
    this.getCurrencyOptions();
  }

}
