import * as CryptoJS from "crypto-js";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CryptoService {
  private bachelor = "f9d8e4c2b51f0a84e1a9c5678b3e7c2b97d2f3e4a5c6d8f0e1a9b3c7d5e4f8a2"; // 🔴 Fetch this from backend securely

  decryptToken(encryptedText: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedText, this.bachelor);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  getDecryptedValues() {
    return {
      PM_DASHBOARD_AUTH: this.decryptToken(environment.ENCRYPTED_VALUES.PM_DASHBOARD_AUTH),
      STRIPE_PUBLISHABLE_KEY: this.decryptToken(environment.ENCRYPTED_VALUES.STRIPE_PUBLISHABLE_KEY),
      CLOUD_AUTH_TOKEN: this.decryptToken(environment.ENCRYPTED_VALUES.CLOUD_AUTH_TOKEN),
      SCHEDULAR_SECRET_KEY: this.decryptToken(environment.ENCRYPTED_VALUES.SCHEDULAR_SECRET_KEY),
      GEO_IP_ACCESS_KEY: this.decryptToken(environment.ENCRYPTED_VALUES.GEO_IP_ACCESS_KEY),
      SASSPAY_IFRAME_TOKEN: this.decryptToken(environment.ENCRYPTED_VALUES.SASSPAY_IFRAME_TOKEN),
      NATASHA_REPO_APP_TOKEN: this.decryptToken(environment.ENCRYPTED_VALUES.NATASHA_REPO_APP_TOKEN),
      ENTERPRISE_SECRET_KEY: this.decryptToken(environment.ENCRYPTED_VALUES.ENTERPRISE_SECRET_KEY),
    };
  }
}
