import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Angulartics2Segment } from "angulartics2/segment";
import { Subscription } from "rxjs";
import { ApiRequests } from "@shared/services/api-requests.service";
import { Constants } from "@shared/utils/constants";
import { PhaseService } from "@shared/services/phase.service";
import { PartnerDashboardApiService } from "@featuremodules/partner-dashboard-module/Utils/partner-dashboard-api.service";
import { DataService } from "@shared/services/data.service";
import { PartnerDashboardDataService } from "@featuremodules/partner-dashboard-module/Utils/partner-dashboard-data.service";
import { UserModel } from "@models/user-model/user.model";
import { ModelMapperService } from "@shared/services/model-mapper.service";
import { AppDataService } from "@rootmodule/app-data.service";
import { Intercom } from "ng-intercom";
import {
  SocialAuthService,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angularx-social-login";
import { CurrencyModel } from "@models/currency-model/currency.model";
import { UtilityService } from "@shared/services/utility.service";
import { CommonService } from "@shared/services/common.service";
import { HomeTemplateService } from "@shared/services/home-template.service";
import { BuildCardModel } from "@models/build-card-model/build-card.model";
declare const fbq: any;
declare const gtag: any;
import { forkJoin } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SocialUser } from "angularx-social-login";
import parsePhoneNumber from "libphonenumber-js";
import { timeout } from "rxjs-compat/operator/timeout";

@Component({
  selector: "login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent {
  currencyByIp: any;
  isSocialSignUp = false;
  selectedCurrency: any;
  isMandatorySignUpflow = false;
  signUpSegEvent = true;
  checkTandCValidOnClick = false;
  isCreateAccountWithAuth = false;
  isShowIpBasedCurrencyDropdown = false;
  public isVisible = false;
  userData = {
    inviteToken: null,
    signup_stage: null,
    utm_campaign: null,
    utm_source: null,
    utm_medium: null,
    utm_content: null,
    utm_term: null,
    utm_GCLID: null
  };
  @ViewChild("loginScreen") public loginScreen;
  isSmallImage = false;
  public errorMessage;
  public login_data = { email: "", password: "", invite_token: null };
  public signup_data: any = {
    first_name: null,
    last_name: "",
    email: null,
    password: null,
    phone_number: "",
    invite_token: null,
    user_type: null,
    organisation: null,
  };
  public socialSignup = {
    email: "",
    phone_number: "",
  };
  public signupTerm1 = false;
  public signupTerm2 = false;
  public sub;
  public success_error_Msg = "";
  public resetData = { email: "", url: "http://localhost:8080/reset-password" };
  public showForgotPassword = false;
  public loginPass;
  public registerPass;
  public ErrorMsg = "";
  public preventClick = false;
  @ViewChild("loginForm") public loginForm;
  public isEmailValid = false;
  public checkingEmailValid = false;
  public validationStarted = false;
  public needValidation = false;
  public countryCode = 1;
  public phoneNumber = "";
  public otpString;
  public signupNextStepIndex = 0;
  public signupNextStepNumberInitiated = 0;
  public signupNextStepPwdInitiated = 0;
  public setIndex = 0;
  public otpError;
  public otpLoader = false;
  public loginButtonClass = "";
  public registerButtonClass = "";
  public signUpNextButtonClass = "";
  public sendButtonClass = "";
  public resendVerificationClass = "";
  public countrySTDCode = "";
  public isLogginButtonClicked = false;
  public onFocus = false;
  public emailChecktimeout = null;
  public SignUpSocialPlatform = "";
  @ViewChild("exit_email") public exit_email;

  @ViewChild("signup_email") public signup_email;
  @ViewChild("signup_password") public signup_password;
  @ViewChild("firstName") public firstName;
  @ViewChild("lastName") public lastName;
  @ViewChild("firstlastname") public firstlastname;
  @ViewChild("mobileNumber") public mobileNumber;
  @ViewChild("email") public email;
  @ViewChild("password") public password;
  @ViewChild("otpSubmitBtn") public otpSubmitBtn;
  @ViewChild("registerForm") public registerForm;
  @ViewChild("forgot_email") public forgot_email;
  @ViewChild("verification_email") public verification_email;
  @ViewChild("divClicked") divClicked;
  @ViewChild("regName") registrationName: ElementRef;
  @ViewChild("forgetemail") forgotEmailElement: ElementRef;
  public validateSubscription: Subscription;

  @ViewChild("otp1") public otp1;
  @ViewChild("otp2") public otp2;
  @ViewChild("otp3") public otp3;
  @ViewChild("otp4") public otp4;
  @ViewChild("otpForm") public otpForm: any;
  public isOtpFormVisible = false;
  public isOtpSent = false;
  public resendLinkClicked = false;
  public partnerResendClicked = false;
  public showVerificationMessage = false;
  public successMsg;
  currentActiveTab = "User";
  public checkPartnerEmail = false;
  public isEmailExist = false;
  // public emailhostObj: Object;
  public emailHosts: string[];
  public hostEmails: Array<string> = new Array<string>();
  public copyHostEmails: Array<string> = new Array<string>();
  public showEmailHosts = false;
  public fullName: string;
  public arrowkeyLocation = -1;
  public isScrolling = false;
  public isSelectionOnEnterKey = false;
  public toCheck = false;
  public isSubmitted = false;
  public isSendClicked = false;
  public isNextClicked = false;
  public checkNameValidOnClick = false;
  public checkNumberValidOnClick = false;
  public checkPwdValidOnClick = false;
  public checkValidEmailOnLogin = false;
  public checkValidPwdOnLogin = false;
  public checkOnForgotEmail = false;
  public showEmailError = false;
  public showPwdError = false;
  public checkEmailError = false;
  public showForgotEmailError = false;
  public showResendEmailError = false;
  public showServerError = false;
  defalutMainHeadingsForSignupModal = [
    { screenName: "sign_up_1", message: "Get started with Builder" },
    { screenName: "sign_up_2", message: "Instant quote" },
    { screenName: "login", message: "Welcome" },
  ];
  defalutSubHeadingsForSignupModal = [
    { screenName: "sign_up_1", message: "Price your idea, absolutely free" },
    { screenName: "sign_up_2", message: "for your product" },
    { screenName: "login", message: "Sign in to Builder" },
  ];
  shouldValidateEmail = false;
  shouldValidatePassword = false;
  isSocialSignClicked = false;
  signUpButtonClicked = false;
  public call_type = "";
  /*@ngInject*/
  //noinspection JSDeprecatedSymbols
  constructor(
    public appDataService: AppDataService,
    public phaseService: PhaseService,
    public homeTemplateService: HomeTemplateService,
    public dataService: DataService,
    public router: Router,
    public auth: SocialAuthService,
    public analyticsSegment: Angulartics2Segment,

    public apiRequest: ApiRequests,
    public partnerApiService: PartnerDashboardApiService,
    public partnerDataService: PartnerDashboardDataService,
    private modelMapperService: ModelMapperService,
    private zone: NgZone,
    public utilityService: UtilityService,
    public commonService: CommonService,
    private renderer: Renderer2,
    public http: HttpClient
  ) {
    this.signUpSegEvent = true;
    this.errorMessage = "";
    this.loginPass = { show: false, text: "show" };
    this.registerPass = { show: false, text: "show" };
    this.signup_data.phone_number = this.appDataService.urlParameters
      .phone_number
      ? this.appDataService.urlParameters.phone_number.replace("-", "")
      : "";
    this.signup_data.email = this.appDataService.urlParameters?.email
      ? this.appDataService.urlParameters?.email
      : "";
    this.login_data.email = this.appDataService.urlParameters?.email
      ? this.appDataService.urlParameters?.email
      : "";
    this.signup_data.first_name = this.appDataService.urlParameters?.name
      ? this.appDataService.urlParameters?.name.split(" ")[0]
      : "";
    this.signup_data.last_name = this.appDataService.urlParameters?.name
      ? this.appDataService.urlParameters?.name.split(" ")[1]
      : "";
    if (this.appDataService.urlParameters?.name) {
      this.fullName = this.appDataService.urlParameters?.name.replace(" ", " ");
    }
    if (sessionStorage.getItem("userNameFromNatasha")) {
      this.fullName = sessionStorage.getItem("userNameFromNatasha");
    }
  }

  public onScrollRightSection(e) {
    if (e.target.scrollTop > 20) {
      this.isSmallImage = true;
    } else {
      this.isSmallImage = false;
    }
  }

  public toggleSignInAndSignUp(isFromForgotPassword?) {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "sign_up_clicked",
      {
        user_id: this.dataService.user?.id,
      }
    );
    this.ErrorMsg = null;
    this.phaseService.showForgotPassword = false;
    this.showForgotPassword = false;
    this.isSendClicked = false;
    this.phaseService.showRegister = !this.phaseService.showRegister;
    if (this.currencyByIp.length === 1) {
      this.selectedCurrency = this.currencyByIp[0];
    }
    if (isFromForgotPassword) {
      this.signup_data.email = this.resetData.email;
    }
  }

  public showSignUp() {
    this.isSocialSignUp = false;
    this.phaseService.showRegister = true;
    this.dataService.user = null;
    this.dataService.unsetCookie();
  }

  public getCurrencyByIp() {
    this.apiRequest.getCurrencyByIp().subscribe(
      (data: any) => {
        this.currencyByIp = data.data;
        this.currencyByIp = this.currencyByIp.map((currency) => {
          return currency.attributes;
        });
        if (this.currencyByIp.length === 1) {
          this.selectedCurrency = this.currencyByIp[0];
          this.phaseService.selectedCurrency = this.selectedCurrency;
          this.isShowIpBasedCurrencyDropdown = false;
        }
      },
      (error) => {
        if (
          this.commonService.isPlatformBrowser &&
          (error.status === 403 || error.status === 401 || error.status === 404)
        ) {
          this.dataService.invalidAccess = true;
          this.router.navigate(["invalidaccess"]);
        }
      }
    );
  }

  closeCurrencySelection() {
    this.isShowIpBasedCurrencyDropdown = false;
  }

  public toggleCurrenctDropdown(e?) {
    e.stopPropagation();
    if (this.currencyByIp.length === 1) {
      this.isShowIpBasedCurrencyDropdown = true;
    }
    this.isShowIpBasedCurrencyDropdown = !this.isShowIpBasedCurrencyDropdown;
  }

  public isMarketingSignFlow() {
    if (this.appDataService.urlParameters.show_signup) {
      this.isMandatorySignUpflow = true;
    }
  }

  public ngOnInit() {
    // this.dataService.countryNameCode = this.dataService.country_code;
    const scheduleType = this.appDataService.urlParameters.schedular_reason;
    this.call_type =
      scheduleType == "book-demo"
        ? "demo"
        : scheduleType == "book-spec"
        ? "spec call"
        : "";
    this.isMarketingSignFlow();
    this.checkUserHasCurrency();
    if (this.appDataService.urlParameters.is_sales_user) {
      setTimeout(() => {
        this.currencyByIp = this.phaseService.getCurrencies();
      }, 1000);
    } else {
      this.getCurrencyByIp();
    }
    this.phaseService.countryNameCode = this.dataService.country_code
      ? this.dataService.country_code.toLowerCase()
      : "";
    this.setPageTitleAndTrack();
    this.apiRequest.getUserData();
    if (
      !this.dataService.isEngineerAIBuilder() &&
      this.dataService.homePageData &&
      this.dataService.homePageData.builder_partner &&
      !this.dataService.homePageData.builder_partner.is_customer_allowed
    ) {
      this.currentActiveTab = "Partner";
    }
    this.emailHosts = ["gmail.com", "yahoo.com", "outlook.com", "aol.com"];
    if (this.phaseService.enteredEmailonExitIntent) {
      if (this.phaseService.showSignin) {
        this.login_data.email = this.phaseService.enteredEmailonExitIntent;
      } else if (this.phaseService.showRegister) {
        this.phaseService.signupNextStep = true;
        this.signup_data.email = this.phaseService.enteredEmailonExitIntent;
      }
      this.phaseService.enteredEmailonExitIntent = null;
    }
    this.getUserDetailsFromToken();
  }

  ngOnDestroy() {
    this.phaseService.setComponentTitle(this.analyticsSegment, "");
  }

  changeSelectedTab(tabName) {
    this.currentActiveTab = tabName;
  }

  public isShowLoginSignupPopup(): boolean {
    return (
      this.isShowLoginPopup() ||
      this.isShowSignupPopup() ||
      (this.isShowForgotPopup() && !this.phaseService.showTimer)
    );
  }

  public isShowLoginPopup(): boolean {
    return this.phaseService.showSignin;
  }

  public isShowV2SignUpPopup(): boolean {
    return this.phaseService.showV2SignUpPopup;
  }

  public isShowV2LoginPopup(): boolean {
    return this.phaseService.showV2LoginPopup;
  }

  public isShowV2RegisterPopup(): boolean {
    return this.phaseService.showV2RegisterPopup;
  }

  public isCommonAuthPopup(): boolean {
    return this.phaseService.openCommonAuth;
  }

  public isShowSignupPopup(): boolean {
    return this.phaseService.showRegister;
  }

  public isShowForgotPopup(): boolean {
    return this.phaseService.showForgotPassword;
  }

  public isNotForceLogin(): boolean {
    return (
      !this.appDataService.urlParameters.invite &&
      !this.phaseService.forceLogin &&
      !this.phaseService.progressSubUrl &&
      !this.isFromV2()
    );
  }

  public isFromV2(): boolean {
    return (
      this.phaseService.showV2SignUpPopup ||
      this.phaseService.showV2LoginPopup ||
      this.phaseService.showV2RegisterPopup
    );
  }

  getMainHeading(screen) {
    let mainHeading = "";
    this.dataService.signupModalDynamicContent.forEach((element) => {
      if (
        !this.phaseService.conditionToCreateAndSyncBuildCardToBNow() &&
        element.event_source === this.dataService.signupSrcName &&
        element.page_name === screen
      ) {
        mainHeading = element.content_1;
      }
    });
    if (!mainHeading) {
      this.dataService.signupModalDynamicContent.forEach((element) => {
        if (
          this.phaseService.conditionToCreateAndSyncBuildCardToBNow() &&
          element.event_source === "redirect_to_now" &&
          element.page_name === screen
        ) {
          mainHeading = element.content_1;
        }
      });
    }
    if (!mainHeading) {
      this.defalutMainHeadingsForSignupModal.forEach((element) => {
        if (element.screenName === screen) {
          mainHeading = element.message;
        }
      });
    }
    return mainHeading;
  }

  getSubHeading(screen) {
    let subHeading = "";
    this.dataService.signupModalDynamicContent.forEach((element) => {
      if (
        !this.phaseService.conditionToCreateAndSyncBuildCardToBNow() &&
        element.event_source === this.dataService.signupSrcName &&
        element.page_name === screen
      ) {
        subHeading = element.content_2;
      }
    });
    if (!subHeading) {
      this.dataService.signupModalDynamicContent.forEach((element) => {
        if (
          this.phaseService.conditionToCreateAndSyncBuildCardToBNow() &&
          element.event_source === "redirect_to_now" &&
          element.page_name === screen
        ) {
          subHeading = element.content_2;
        }
      });
    }
    if (!subHeading) {
      this.defalutSubHeadingsForSignupModal.forEach((element) => {
        if (element.screenName === screen) {
          subHeading = element.message;
        }
      });
    }
    return subHeading;
  }

  public checkSignupValidity(): boolean {
    if (this.fullName) {
      if (this.fullName.split(" ").length > 1) {
        this.signup_data.first_name = this.fullName
          .split(" ")
          .slice(0, -1)
          .join(" ");
        this.signup_data.last_name = this.fullName
          .split(" ")
          .slice(-1)
          .join(" ");
      } else {
        this.signup_data.first_name = this.fullName;
        this.signup_data.last_name = "";
      }
    }
    return (
      !this.signup_data.email ||
      !this.signup_data.first_name ||
      !this.signup_data.password ||
      (this.signup_data.email &&
        !!this.signup_data.email.errors &&
        (this.signup_data.email.dirty || this.signup_data.email.pristine)) ||
      (this.signup_password != null &&
        !!this.signup_password.errors &&
        (this.signup_password.dirty || this.signup_password.pristine)) ||
      (this.firstlastname &&
        !!this.firstlastname.errors &&
        (this.firstlastname.dirty || this.firstlastname.pristine)) ||
      (this.mobileNumber &&
        !!this.mobileNumber.errors &&
        !this.mobileNumber.dirty &&
        !this.mobileNumber.pristine) ||
      this.checkIfMobileValid()
    );
  }

  public checkSigninValidity(): boolean {
    return (
      !this.phaseService.validateEmail(this.login_data.email) ||
      // && (this.email.dirty || this.email.touched))
      (this.password &&
        !!this.password.errors &&
        (this.password.invalid || this.password.pristine))
    );
  }

  public isPasswordError(isSignIn?: boolean): boolean {
    if (isSignIn && this.shouldValidatePassword) {
      return (
        this.password &&
        !!this.password.errors &&
        (this.password.pristine || this.password.invalid)
      );
    } else if (!isSignIn) {
      if (this.isSubmitted) {
        return (
          this.signup_password &&
          !!this.signup_password.errors &&
          (this.signup_password.pristine || this.signup_password.invalid)
        );
      } else {
        return (
          this.signup_password &&
          this.signup_password.errors &&
          (this.signup_password.dirty || this.signup_password.touched)
        );
      }
    }
  }

  public isPasswordLengthCorrect(isSignIn?: boolean): boolean {
    if (isSignIn) {
      return (
        this.password &&
        this.password.errors.minlength &&
        !this.isPasswordRequiredError(isSignIn) &&
        (this.password.pristine || this.password.invalid)
      );
    }
    return (
      this.signup_password &&
      this.signup_password.errors.minlength &&
      !this.isPasswordRequiredError(isSignIn)
    );
  }

  public isPasswordRequiredError(isSignIn?: boolean): boolean {
    if (isSignIn && this.shouldValidatePassword) {
      return (
        this.password &&
        this.password.errors &&
        (this.password.pristine || this.password.dirty) &&
        !this.password.errors.minlength
      );
    } else if (!isSignIn) {
      if (this.isSubmitted) {
        return (
          this.signup_password &&
          this.signup_password.errors &&
          (this.signup_password.pristine || this.signup_password.dirty) &&
          !this.signup_password.errors.minlength
        );
      } else {
        return this.signup_password && this.signup_password.errors.required;
      }
    }
  }

  public checkIfEmailValid(
    isSignIn: boolean,
    isPartnerSignUp?: boolean
  ): boolean {
    clearTimeout(this.emailChecktimeout);
    if (isSignIn && this.shouldValidateEmail) {
      return !this.phaseService.validateEmail(this.login_data.email);
    } else if (!isSignIn) {
      return !this.phaseService.validateEmail(this.signup_data.email);
    }
  }

  public checkIfValidSocialemail() {
    return this.phaseService.validateEmail(this.socialSignup.email);
  }

  public checkIfForgetEmailValid(): boolean {
    if (this.isSendClicked) {
      return (
        this.forgot_email &&
        this.forgot_email.errors &&
        (!this.phaseService.validateEmail(this.resetData.email) ||
          this.forgot_email.pristine)
      );
    } else {
      if (this.setIndex === 0 && this.forgotEmailElement) {
        this.forgotEmailElement.nativeElement.focus();
        this.setIndex++;
      }
      return (
        this.forgot_email &&
        !this.phaseService.validateEmail(this.resetData.email)
      );
    }
  }

  public checkIfMobileValid(): boolean {
    if (this.isSubmitted) {
      return (
        this.mobileNumber &&
        this.mobileNumber.errors &&
        (this.mobileNumber.invalid || this.mobileNumber.pristine)
      );
    } else {
      return (
        this.mobileNumber &&
        this.mobileNumber.errors &&
        (this.mobileNumber.dirty || this.mobileNumber.touched)
      );
    }
  }

  public checkLastNameValid(): boolean {
    return (
      this.mobileNumber &&
      this.lastName.errors &&
      (this.lastName.dirty || this.lastName.touched)
    );
  }

  public checkFirstNameValid(): boolean {
    // if (this.phaseService.signupNextStep && this.signupNextStepIndex === 0 && this.registrationName) {
    //   if (!this.phaseService.isPartnerDashboard()) {
    //     this.registrationName.nativeElement.focus();
    //   }

    this.signUpSegEvent = false;
    this.signupNextStepIndex++;
    // }
    this.firstName = this.signup_data.first_name;
    if (this.isSubmitted) {
      return (
        this.firstlastname &&
        this.firstlastname.errors &&
        (this.firstlastname.invalid || this.firstlastname.pristine)
      );
    } else {
      return (
        this.firstlastname &&
        this.firstlastname.errors &&
        (this.firstlastname.dirty || this.firstlastname.touched)
      );
    }
  }

  public showLoginIn(form: FormGroup, signUpSrcName?) {
    this.dataService.signupSrcName = signUpSrcName;
    if (!this.phaseService.signupNextStep) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "sign_in_clicked",
        {
          screen_name: "Signup_popup",
          user_id: this.dataService.user?.id,
        }
      );
    } else if (this.phaseService.signupNextStep) {
      this.phaseService.trackEventForObject(
        this.analyticsSegment,
        "sign_in_clicked",
        {
          screen_name: "Signup_popup2",
          user_id: this.dataService.user?.id,
        }
      );
    }

    this.checkEmailError = false;

    if (!this.preventClick) {
      if (form) {
        form.reset();
      }
      this.registerPass = { show: false, text: "show" };
      this.signup_data = {
        first_name: null,
        last_name: "",
        email: null,
        phone_number: "",
        password: null,
        invite_token: null,
        organisation: null,
      };
      this.resetErrorMsgsOnSignupScreen();
      this.resetSigninForm();
      if (this.phaseService.showV2SignUpPopup) {
        this.phaseService.showV2LoginPopup = true;
        this.phaseService.showV2SignUpPopup = false;
      }
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      this.phaseService.showRegister = false;
      this.phaseService.signupNextStep = false;
      this.isSocialSignUp = false;
      this.ErrorMsg = "";
      this.setPageTitleAndTrack();
    }
  }

  public showRegisterMethod(form: FormGroup) {
    this.dataService.signupSrcName = "default_sign_up";
    this.showEmailError = false;
    this.showPwdError = false;
    if (!this.preventClick) {
      form.reset();
      this.loginPass = { show: false, text: "show" };
      this.login_data = { email: "", password: "", invite_token: null };
      if (this.phaseService.isPartnerDashboard()) {
        this.phaseService.signupNextStep = true;
        this.dataService.signupSrcName = "default_sign_up";
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
        this.phaseService.openCommonAuth = true;
      } else {
        if (this.phaseService.showV2LoginPopup) {
          this.phaseService.showV2SignUpPopup = true;
          this.phaseService.showV2LoginPopup = false;
        }
        this.phaseService.signupNextStep = false;
        this.phaseService.showSignin = false;
        this.phaseService.showRegister = true;
        this.phaseService.openCommonAuth = true;
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "sign_up_clicked",
          {
            user_id: this.dataService.user?.id,
          }
        );

        this.ErrorMsg = "";
        this.setPageTitleAndTrack();
      }
    }
  }

  public emptyBuildCard() {
    this.dataService.modifiedBuildCardData = undefined;
    this.dataService.buildCardData = new BuildCardModel();
  }

  fetchHomePageData() {
    this.apiRequest.fetchHomePageData().subscribe((data: any) => {
      this.dataService.dataLoadingCurrency = Object.assign(
        {},
        this.dataService.homePageData.currency
      );
      this.phaseService.platformPrices = Array.apply(
        null,
        Array(this.dataService.homePageData.platforms.length)
      );
      this.phaseService.platformPrices.map((x, i) => {
        return i;
      });
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price =
          this.dataService.homePageData.currency.specing_price;
      }
    });
  }

  redirectToDestination() {
    const origin = window.location.origin;
    if (this.appDataService.urlParameters.redirection_url.includes(origin)) {
      const path = this.appDataService.urlParameters.redirection_url.replace(
        origin,
        ""
      );
      window.location.href = path;
      // let params = this.getQueryParamsFromURL(path);
      // this.assignParamsToUrlParameterModel(params);
      // this.router.navigateByUrl(path);
    } else {
      let code = this.appDataService.urlParameters.uniq_code;
      if (
        this.appDataService.urlParameters.redirection_url.includes(
          "upgrade_to_store"
        )
      ) {
        window.location.href =
          environment.FE_URL +
          "/upgrade?upgrade_to_store=true&uniq_code=" +
          code;
      } else if (
        this.appDataService.urlParameters.redirection_url.includes(
          "upgrade_to_starter"
        )
      ) {
        window.location.href =
          environment.FE_URL +
          "/upgrade?upgrade_to_starter=true&uniq_code=" +
          code;
      } else {
        window.location.href =
          this.appDataService.urlParameters.redirection_url;
      }
    }
  }

  public login_click(form?) {
    this.ErrorMsg = "";
    if (!this.preventClick) {
      this.preventClick = true;
      if (this.appDataService.urlParameters.inviteToken) {
        this.login_data.invite_token =
          this.appDataService.urlParameters.inviteToken;
      }
      this.login_data.email = this.login_data.email.toLowerCase();
      if (
        this.dataService.dynamicPricingSetting.isChanged ||
        this.dataService.featureRemovalConfirmSetting.isChanged ||
        this.dataService.featureRecommendationsConfirmSetting.isChanged
      ) {
        this.dataService.loginData.user_setting_attributes = {
          dynamic_pricing: this.dataService.dynamicPricingSetting.value,
          feature_removal_confirmation:
            this.dataService.featureRemovalConfirmSetting.value,
          is_recommendation_on:
            this.dataService.featureRecommendationsConfirmSetting.value,
        };
      }
      this.apiRequest.login_signup(this.login_data, "login").subscribe(
        (data: any) => {
          if (
            Object.keys(this.dataService.user).length &&
            !this.dataService.user.currency
          ) {
            this.isSocialSignUp = true;
            return;
          }
          if (
            this.appDataService.urlParameters.payment_gateway &&
            this.appDataService.urlParameters.payment_id
          ) {
            this.router.navigate(["dashboard"]);
          }
          if (this.appDataService.urlParameters.redirection_url) {
            this.redirectToDestination();
          }
          if (this.appDataService.urlParameters.schedular_reason) {
            this.showSchedular();
          }
          if (
            this.appDataService.urlParameters.upgrade_to_store ||
            this.appDataService.urlParameters.upgrade_to_starter
          ) {
            this.getBuildCardData(this.appDataService.urlParameters.uniq_code);
          }
          if (this.appDataService.urlParameters.email_confirm_token) {
            const data = {
              token: this.appDataService.urlParameters.email_confirm_token,
            };
            this.apiRequest.emailConfirm(data).subscribe((resp: any) => {
              this.phaseService.emailConfirmedPoupup = true;
              if (resp.message == "User already verified") {
                this.phaseService.userAlreadyVeriied = true;
              }
            });
          }
          if (this.dataService.currentPage !== "templateDetail") {
            this.fetchHomePageData();
          }
          this.phaseService.fetchUserShowData();
          this.loginButtonClass = "successAnimation";
          this.phaseService.mobUserLogin = false;
          this.phaseService.AppDiscountSuccessfullyApplied =
            this.phaseService.isAppDiscountApplied;
          if (this.phaseService.AppDiscountSuccessfullyApplied) {
            this.phaseService.popupCoupon = true;
          }
          this.phaseService.hidesignupPopup();
          if (this.phaseService.popupCoupon) {
            this.phaseService.popupCouponApplied = true;
          }
          if (data.user && data.user.email) {
            this.phaseService.trackEventForObject(
              this.analyticsSegment,
              "sign_in_successful",
              {
                platform_source: "email",
                screen_source: "Studio Pro",
                user_id: this.dataService.user?.id,
              }
            );
          }
          if (
            this.homeTemplateService.createBuildCardPending &&
            this.phaseService.selectedApps &&
            this.phaseService.selectedApps.length > 0
          ) {
            // this.createBuildCardAfterLoginSignUp();
          }

          setTimeout(() => {
            this.dataService.isShowTemplateModal = false;
            const userObj: UserModel = new UserModel();
            data.user = this.modelMapperService.getMappedModel(
              userObj,
              data.user
            );
            this.dataService.loginData = data.user;
            this.phaseService.promotionCurrencySubject.next(true);
            this.phaseService.updateCurrencyChange(true);
            this.phaseService.clearCurrencyUpdateMessage();
            if (this.dataService.homePageData.currency) {
              this.phaseService.specing_price =
                this.dataService.homePageData.currency.specing_price;
            }
            if (this.phaseService.selectedHash.cloud_price > 0) {
              this.phaseService.convertCloudPriceBasedOnUserCurrency();
            }
            this.resetSigninForm();
            this.phaseService.showSignin = false;
            this.phaseService.openCommonAuth = false;
            let redirectUrl = sessionStorage.getItem(
              "featurecard-buildcard-param"
            );
            if (
              this.appDataService.urlParameters.red &&
              this.appDataService.urlParameters.red === "now"
            ) {
              this.router.navigate([""]);
            }
            if (redirectUrl && this.dataService.user) {
              let equalSignIndex = redirectUrl.indexOf("=");
              let buildCardCode = redirectUrl.substr(equalSignIndex + 1);
              this.router.navigate(["/features"], {
                queryParams: { buildcard: buildCardCode },
              });
            } else if (this.phaseService.gotoCreateNew) {
              this.phaseService.gotoCreateNew = false;
              this.phaseService.showSaveProgress = false;
              this.emptyBuildCard();
              this.dataService.unsavedChanges = false;
              this.phaseService.selectedApps = [];
              this.router.navigate([this.dataService.firstPage]);
            } else if (this.dataService.sendToProjectProgress) {
              this.router.navigate([this.phaseService.progressSubUrl]);
            } else if (this.dataService.meetNowScheduleCall) {
              this.dataService.meetNowScheduleCall = false;
              // this.dataService.hideExitIntentPopUp = true;
              this.phaseService.createNormallOrInstantSpecCall("normal");
            } else if (this.dataService.meetNowConnectCall) {
              this.dataService.meetNowConnectCall = false;
              this.dataService.isAfterAuthentication = true;
              this.phaseService.showMeetNowSearchingScreen = true;
              this.phaseService.createNormallOrInstantSpecCall("instant");
            } else if (this.phaseService.isShowSpecPopup) {
              this.phaseService.isOpenSpecing = true;
            } else if (this.phaseService.loadDashBoard) {
              this.phaseService.loadDashBoard = false;
              this.phaseService.handleDashboardLoading.next(true);
            } else if (this.phaseService.isNdaFlow) {
              this.phaseService.isNdaFlow = false;
              this.fetchDocusignUrl();
            } else if (this.dataService.toDashBoard) {
              this.router.navigate(["dashboard"]);
            } else if (this.dataService.isBuildCardUrl) {
              this.router.navigate([this.dataService.buildCardLink]);
            } else if (this.phaseService.becomePartner) {
              this.router.navigate(["become-a-partner"]);
            } else if (
              this.appDataService.urlParameters.red === "meet_now" ||
              this.appDataService.urlParameters.red === "ip_now"
            ) {
              this.dataService.showMeetNowModel = true;
            } else if (this.dataService.pendingURL) {
              const goToHome = this.dataService.pendingURL;
              this.dataService.pendingURL = "";
              this.router.navigate([goToHome]);
            } else if (this.phaseService.showV2LoginPopup) {
              this.router.navigate(["home"]);
            } else if (this.appDataService.urlParameters.cpe_user) {
              this.handleFlowForStudioStore();
            } else if (this.dataService.openMeetNowFromAppDetail) {
              if (
                this.appDataService.urlParameters.cpe_user &&
                this.appDataService.urlParameters.uniq_code
              ) {
                sessionStorage.setItem(
                  "show_natasha_again",
                  "not_from_template_page"
                );
                this.router.navigate(["feature"], {
                  queryParams: {
                    uniq_code: this.appDataService.urlParameters.uniq_code,
                    cpe_user: "true",
                  },
                });
              } else {
                sessionStorage.setItem(
                  "show_natasha_again",
                  "not_from_template_page"
                );
                this.router.navigate(["feature"]);
              }
            } else if (this.dataService.buildCardUniqueCode) {
              this.router.navigate([
                "build-card/" + this.dataService.buildCardUniqueCode,
              ]);
            } else if (this.phaseService.featureRequestUniqueCode) {
              this.router.navigate(
                [this.phaseService.featureRequestUniqueCode + "/feature"],
                { queryParams: { featureRequest: "true" } }
              );
            } else {
              this.saveData();
              if (
                this.dataService.openMeetNowModal &&
                !this.dataService.checkIfCPEUser()
              ) {
                this.dataService.showMeetNowModel = true;
              }
            }
            this.phaseService.trackSigninEvents();
            this.preventClick = false;
            if (this.phaseService.popupCoupon) {
              this.signupGetCode();
            }
            const promoValid = this.phaseService.isValidPromotion();
            this.setUrlpromotion(promoValid);
            this.phaseService.showV2LoginPopup = false;
            this.phaseService.showV2SignUpPopup = false;
            this.phaseService.checkExitIntentTimer();
            let type = "single";
            if (
              this.homeTemplateService.activeFilters.category.length > 0 ||
              this.homeTemplateService.activeFilters.price.length > 0
            ) {
              type = "filter_sort";
            } else if (this.homeTemplateService.sortingOption) {
              type = "sorting";
            }
            this.homeTemplateService.clearFilter("price", true);
            this.homeTemplateService.clearSearch();
            this.homeTemplateService.currencyChange.next({
              currencyId: this.dataService.user.currency.id,
              type: type,
              filters: this.homeTemplateService.activeFilters,
              sorting: this.homeTemplateService.sortingOption,
            });
            this.renderer.removeClass(
              document.getElementById("app-component"),
              "hide-scroll-bar"
            );
            this.renderer.removeClass(document.body, "modal-open");
          }, 100);
        },
        (error) => {
          this.phaseService.checkExitIntentTimer();
          this.showServerError = true;
          if (error && error.error) {
            if (error.error.errors.email) {
              this.ErrorMsg = "Email " + error.error.errors.email;
            } else if (error.error.errors.password) {
              this.ErrorMsg = "Password " + error.error.errors.password;
            }
          } else {
            this.ErrorMsg = error.error.message;
          }
          this.loginButtonClass = "errorAnimation";
          this.preventClick = false;
        }
      );
    }
  }

  createBuildCardAfterLoginSignUp() {
    this.phaseService.checkAndSetDefaultPhases();
    this.dataService.homePageData.build_phases.forEach((phase) => {
      if (
        phase.selected &&
        !phase.title.includes("MVP") &&
        phase["type"] !== "custom_phase"
      ) {
        phase.features = [];
        phase.features.push(...this.phaseService.selectedFeatures);
      } else if (
        phase.selected &&
        (phase.title.includes("MVP") || phase["type"] === "custom_phase")
      ) {
        if (
          (this.phaseService.selectedFeatures &&
            this.phaseService.selectedFeatures.length === 0) ||
          (phase.features &&
            phase.features.length > 0 &&
            phase.features[0] === undefined)
        ) {
          phase.features = [];
        }
        if (phase.features && phase.features.length > 0) {
          phase.features.forEach((feature, featureIndex) => {
            const index = this.phaseService.selectedFeatures.findIndex(
              (selectedFeature) => {
                return feature.id === selectedFeature.id;
              }
            );
            if (index === -1) {
              phase.features.splice(featureIndex, 1);
            }
          });
        }
        if (
          this.phaseService.mandatoryFeatures.length > 0 &&
          phase.title === "MVP"
        ) {
          const phaseFeatures = phase.features.map((feature) => feature.id);
          this.phaseService.mandatoryFeatures.forEach((feature) => {
            if (phaseFeatures.indexOf(feature.id) === -1 && feature.selected) {
              feature["selected"] = true;
              feature["mvpSelected"] = true;
              phase.features.push(feature);
            }
            feature["mvpSelected"] = true;
            if (!phase.platforms || phase.platforms.length === 0) {
              phase.platforms = this.phaseService.selectedPlatforms;
            }
            feature.platforms = phase.platforms;
          });
        }
      }
      if (
        this.phaseService.selectedApps.length &&
        (phase.title === "MVP" || phase["type"] === "custom_phase")
      ) {
        if (!phase.features) {
          phase.features = [];
        }
        phase.features.forEach((feature) => {
          this.phaseService.selectedFeatures.forEach((ftr) => {
            if (ftr.id === feature.id) {
              ftr.phaseId = feature.phaseId;
              ftr.platforms = Object.assign([], feature.platforms);
            }
          });
        });
      }
    });
    this.phaseService.checkTeamAndSpeed();
    this.phaseService.createBuildCardPriceHash();
    this.phaseService.buildCardCreationStarted = true;
    this.apiRequest
      .createUpdateBuildCard(
        this.phaseService.selectedHash,
        this.phaseService.inProgressBuildCardId,
        false,
        "afterLogin"
      )
      .subscribe(
        (data: any) => {
          if (this.appDataService.urlParameters.schedular_reason) {
            this.showSchedular();
          }
          if (!this.phaseService.inProgressBuildCardId) {
            this.phaseService.isNewBuildCard = true;
          }
          this.phaseService.inProgressBuildCardId =
            this.dataService.buildCardData.id;
          this.dataService.unsavedChanges = false;
          this.dataService.isFromViewPrototype = false;
          this.phaseService.buildCardEditMode = false;
          this.dataService.isEditBuildCard = false;
          this.phaseService.inProgressFlow = false;
          this.phaseService.isBuildCardSetup = false;
          this.phaseService.buildCardCreationStarted = false;
          const buildCardData = this.dataService.buildCardData;
          if (
            (this.phaseService.selectedApps.length ||
              (buildCardData &&
                buildCardData.id &&
                buildCardData.progress.apps.length)) &&
            this.homeTemplateService.createBuildCardPending
          ) {
            this.homeTemplateService.createBuildCardPending = false;
            if (
              !this.homeTemplateService.checkIfNatashaChatsContainUserMessage()
            ) {
              this.homeTemplateService.resetTemplateNatashaChatHistory();
            } else {
              this.homeTemplateService.addSelectedAppNamesToNatashaChat();
              this.phaseService.isCollapseTemplateHistory = true;
            }
            // this.phaseService.natashaChatsList = JSON.parse(JSON.stringify(this.homeTemplateService.tempateNatashaChatList));
            sessionStorage.setItem("show_natasha_again", "from_template_page");
            if (!this.dataService.isShowBantModal && !this.appDataService.urlParameters.is_dls_store_flow) {
              this.router.navigate([
                this.dataService.buildCardData.uniq_code + "/feature",
              ]);
            }
          }
          this.isSocialSignUp = false;
          this.resetSignupForm();
          this.dataService.showHideLoader(false);
        },
        (error) => {
          if (error.status === 403 || error.status === 401) {
            this.dataService.user = null;
            // remove cookie here
            this.dataService.unsetCookie();

            this.phaseService.clearLocalStorage();
            this.phaseService.showSignin = true;
            this.phaseService.openCommonAuth = true;
          }
        }
      );
  }

  handleFlowForStudioStore() {
    if (this.appDataService.urlParameters.cpe_user) {
      this.apiRequest
        .fetchSingleBuildCardData(this.appDataService.urlParameters.uniq_code)
        .subscribe(
          (data: any) => {
            if (
              !(
                this.dataService.user &&
                this.dataService.user.roles === "CPE" &&
                !this.dataService.buildCardData.owner
              )
            ) {
              this.dataService.invalidAccess = true;
              this.router.navigate(["invalidaccess"]);
            }
          },
          (error) => {
            if (
              this.commonService.isPlatformBrowser &&
              (error.status === 403 ||
                error.status === 401 ||
                error.status === 404)
            ) {
              this.dataService.invalidAccess = true;
              this.router.navigate(["invalidaccess"]);
            }
          }
        );
    }
  }

  public setUrlpromotion(promoValid) {
    const promotion =
      this.dataService.manualPromotion ||
      this.dataService.homePageData.promotion;
    if (promotion) {
      if (!promoValid) {
        this.phaseService.promotiontobeApplied();
      } else {
        this.phaseService.isUrlPromotionValid = true;
      }
    }
  }

  public fetchDocusignUrl() {
    const params = { redirect_url: "", build_card_id: "" };
    params.redirect_url = this.phaseService.getRedirectUrl();
    if (this.phaseService.inProgressBuildCardId) {
      params.build_card_id = this.phaseService.inProgressBuildCardId;
    }
    this.apiRequest.fetchDocuSignUrl(params).subscribe((data: any) => {
      if (this.dataService.docuSignUrl) {
        window.location.href = this.dataService.docuSignUrl;
      }
    });
  }

  public changeFocus(event, el) {
    const key = event.keyCode || event.charCode;

    if (key === 8) {
      if (el) {
        setTimeout(() => {
          el.focus();
        }, 0);
      }
    }
  }

  public handleKeyLeft(event, el) {
    const key = event.keyCode || event.charCode;
    if (key === 37) {
      if (el) {
        el.focus();
      }
    }
  }

  public handleKeyRight(event, el) {
    const key = event.keyCode || event.charCode;
    if (key === 39) {
      if (el) {
        el.focus();
      }
    }
  }

  public changeValidationStatus(event) {
    const key = event.keyCode;
    this.needValidation = true;
    this.isEmailValid = false;
    if (key !== undefined && !this.isSelectionOnEnterKey) {
      if (
        (this.signup_data &&
          this.signup_data.email != null &&
          this.signup_data.email.includes("@") &&
          (key !== 8 || key === 8)) ||
        key === 38 ||
        key === 40
      ) {
        this.showEmailHosts = true;
      } else if (key === 13 && this.checkIfEmailValid(false)) {
        this.showEmailHosts = true;
      } else {
        this.showEmailHosts = false;
      }
    }
    if (key === 13) {
      if (
        !this.checkIfEmailValid(false) &&
        !this.isScrolling &&
        !this.isSelectionOnEnterKey
      ) {
        this.signupStepNext();
      }
    } else if (
      this.signup_data &&
      this.signup_data.email &&
      this.signup_data.email.endsWith("@")
    ) {
      this.getEmailHosts();
    }

    this.copyHostEmails = this.hostEmails;
    this.isSelectionOnEnterKey = false;
    if (this.copyHostEmails) {
      this.copyHostEmails = this.copyHostEmails.filter((item) =>
        item.toLowerCase().includes(this.signup_data.email)
      );
    }
  }

  public openOtpPopup() {
    this.clearError();
    this.isOtpFormVisible = true;
    this.preventClick = false;
    if (this.otp1) {
      this.otp1.nativeElement.focus();
    }
  }

  public onSubmitOtp() {
    const otp =
      this.otp1.nativeElement.value +
      this.otp2.nativeElement.value +
      this.otp3.nativeElement.value +
      this.otp4.nativeElement.value;
    if (otp.toString().length === 4) {
      this.otpString = parseInt(otp, 10);
      this.confirmOtpAfterSignup();
    }
  }

  public isOtpSubmitEnabled() {
    return (
      this.otp1 &&
      this.otp1.nativeElement.value &&
      this.otp2 &&
      this.otp2.nativeElement.value &&
      this.otp3 &&
      this.otp3.nativeElement.value &&
      this.otp4 &&
      this.otp4.nativeElement.value
    );
  }

  public enterOtpDigit(el) {
    this.clearError();
    setTimeout(() => {
      el.focus();
    }, 100);
  }

  public clearError() {
    if (this.otpError) {
      this.otpError = null;
    }
  }

  public confirmOtpAfterSignup() {
    this.otpLoader = true;
    this.apiRequest.confirmOTP(this.otpString).subscribe(
      (data) => {
        this.otpLoader = false;
        if (data) {
          this.apiRequest.setUpUserData(data);
          this.phaseService.otpConfirmationMessage = true;
          this.dataService.showUserConfirmationToast = true;

          setTimeout(() => {
            this.phaseService.otpConfirmationMessage = false;
            this.dataService.showUserConfirmationToast = false;
          }, 5000);
          this.afterSignupProcess();
        }
      },
      (error) => {
        this.otpLoader = false;
        const err = error.error;
        this.otpError = err.message ? err.message : null;
      }
    );
  }

  public afterSignupProcess() {
    if (typeof fbq !== "undefined" && typeof gtag !== "undefined") {
      this.phaseService.trackSignupEvents(gtag, fbq);
    }
    this.resetSignupForm();
    if (this.dataService.sendToProjectProgress) {
      this.router.navigate([this.phaseService.progressSubUrl]);
    } else if (this.phaseService.loadDashBoard) {
      this.phaseService.loadDashBoard = false;
      this.phaseService.loadDashBoardData(this.analyticsSegment);
    } else if (this.phaseService.isNdaFlow) {
      this.phaseService.isNdaFlow = false;
      this.fetchDocusignUrl();
    } else if (this.phaseService.isSpecingFlow) {
      this.phaseService.isSpecingFlow = false;
      this.router.navigate(["feature"]);
    } else if (this.dataService.toDashBoard) {
      this.router.navigate(["dashboard"]);
    } else if (this.dataService.isBuildCardUrl) {
      this.router.navigate([this.dataService.buildCardLink]);
    } else {
      this.saveData();
      if (
        this.dataService.openMeetNowModal &&
        !this.dataService.checkIfCPEUser()
      ) {
        this.dataService.showMeetNowModel = true;
      }
    }
    this.preventClick = false;
    const promoValid = this.phaseService.isValidPromotion();
    this.setUrlpromotion(promoValid);
  }

  public sendOTPAfterSignup() {
    this.apiRequest.sendOTPAfterSignup().subscribe((data) => {
      if (data) {
        this.isOtpSent = true;
        if (!this.isOtpFormVisible) {
          this.openOtpPopup();
        }
        setTimeout(() => {
          this.isOtpSent = false;
          this.resendLinkClicked = false;
        }, 2000);
      }
    });
  }

  showSchedular() {
    const scheduleType = this.appDataService.urlParameters.schedular_reason;
    let call_type =
      scheduleType == "book-demo"
        ? "demo"
        : scheduleType == "book-spec"
        ? "spec"
        : "kickoff";
    this.apiRequest.createSpecCall("", "", "", call_type).subscribe(
      (resp) => {
        this.dataService.specingId = resp.id;
        this.phaseService.showScheduleCallFlow = true;
        this.dataService.showMeetNowModel = true;
      },
      (error) => {
        this.dataService.showHideLoader(false);
        this.dataService.isScheduleCall = false;
      }
    );
  }

  setHSandVWOKeysFromCookies() {
    if (window['VWOLastVariantApplied']) {
      this.signup_data['vwo_campaign_id'] = window['VWOLastVariantApplied']['vwo_campaign_id'];
      this.signup_data['vwo_campaign_name'] = window['VWOLastVariantApplied']['vwo_campaign_name'];
      this.signup_data['vwo_variation_id'] = window['VWOLastVariantApplied']['vwo_variation_id'];
      this.signup_data['vwo_variation_name'] = window['VWOLastVariantApplied']['vwo_variation_name'];
    }
  }

  public signup_click(form: FormGroup) {
    // if (!this.preventClick) {
    if (this.signUpButtonClicked) {
      return false;
    }
    this.preventClick = true;
    this.signUpButtonClicked = true;
    if (this.appDataService.urlParameters.inviteToken) {
      this.signup_data["invite_token"] =
        this.appDataService.urlParameters.inviteToken;
    }
    this.signup_data["currency_id"] = this.selectedCurrency.id;
    this.signup_data.user_type = this.currentActiveTab;
    this.signup_data.signup_stage =
      this.appDataService.urlParameters.signup_stage;
    this.signup_data.utm_campaign = this.appDataService.urlParameters.utm_campaign ? this.appDataService.urlParameters.utm_campaign : this.dataService.getCookieForUTMparams('utmCampaign');
    this.signup_data.utm_source = this.appDataService.urlParameters.utm_source ? this.appDataService.urlParameters.utm_source : this.dataService.getCookieForUTMparams('utmSource');
    this.signup_data.utm_medium = this.appDataService.urlParameters.utm_medium ? this.appDataService.urlParameters.utm_medium : this.dataService.getCookieForUTMparams('utmMedium');
    this.signup_data.utm_content = this.appDataService.urlParameters.utm_content ? this.appDataService.urlParameters.utm_content : this.dataService.getCookieForUTMparams('utmContent');
    this.signup_data.utm_term = this.appDataService.urlParameters.utm_term ? this.appDataService.urlParameters.utm_term : this.dataService.getCookieForUTMparams('utmTerm');
    this.signup_data.utm_GCLID = this.dataService.getCookieForUTMparams('utmGCLID');
    this.phaseService.isSignupByChatbot = false;

    this.phaseService.isSignupByChatbot = false;
    if (this.phaseService.clickedpopupsignin) {
      this.phaseService.popupCoupon = true;
    }
    if (
      this.dataService.dynamicPricingSetting.isChanged ||
      this.dataService.featureRemovalConfirmSetting.isChanged ||
      this.dataService.featureRecommendationsConfirmSetting.isChanged
    ) {
      this.signup_data.user_setting_attributes = {
        dynamic_pricing: this.dataService.dynamicPricingSetting.value,
        feature_removal_confirmation:
          this.dataService.featureRemovalConfirmSetting.value,
        is_recommendation_on:
          this.dataService.featureRecommendationsConfirmSetting.value,
      };
    }
    if (this.appDataService.urlParameters.product) {
      this.signup_data['product_type'] = this.appDataService.urlParameters.product;
    }
    this.setHSandVWOKeysFromCookies();
    if (this.appDataService.urlParameters.is_self_serve) {
      this.signup_data.is_self_serve_user = true;
    }
    // if (this.appDataService.urlParameters.is_self_serve) {
    //   this.setHSandVWOKeysFromCookies();
    // }
    this.apiRequest.login_signup(this.signup_data, "signup").subscribe(
      (data: any) => {
        if (this.appDataService.urlParameters.schedular_reason) {
          this.showSchedular();
        } else {
          this.dataService.showMeetNowModel = true;
        }
        if (
          this.appDataService.urlParameters.upgrade_to_store ||
          this.appDataService.urlParameters.upgrade_to_starter
        ) {
          this.getBuildCardData(this.appDataService.urlParameters.uniq_code);
        }
        if (this.dataService.currentPage !== "templateDetail") {
          this.fetchHomePageData();
        }
        this.signUpButtonClicked = false;
        this.registerButtonClass = "successAnimation";
        this.phaseService.AppDiscountSuccessfullyApplied =
          this.phaseService.isAppDiscountApplied;
        if (this.phaseService.AppDiscountSuccessfullyApplied) {
          this.phaseService.popupCoupon = true;
        }
        if (this.phaseService.popupCoupon) {
          this.phaseService.popupCouponApplied = true;
          this.signupGetCode();
        }
        this.phaseService.hidesignupPopup();
        if (this.appDataService.urlParameters.is_freemium) {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            "freemium_new_user_created",
            {}
          );
        }
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "sign_up_successful",
          {
            current_url: window.location.href,
            platform_source: "email",
            screen_source: "Studio Pro",
            user_id: this.dataService.user?.id,
          }
        );
        if (this.homeTemplateService.createBuildCardPending) {
          // this.createBuildCardAfterLoginSignUp();
        }
        setTimeout(() => {
          this.dataService.isShowTemplateModal = false;
          this.afterSignupProcess();

          if (!this.appDataService.urlParameters.cpe_user && this.appDataService.urlParameters.redirection_url) {
            this.emptyBuildCard();
            this.redirectToDestination();
          }

          if (
            this.appDataService.urlParameters.red &&
            this.appDataService.urlParameters.red === "now"
          ) {
            this.phaseService.gotoCreateNew = true;
          }
          this.phaseService.checkExitIntentTimer();
          // if (this.appDataService.urlParameters.v2 && this.appDataService.urlParameters.v2 === '1') {
          //   this.phaseService.signupNextStep = false;
          //   this.phaseService.showV2SignUpPopup = false;
          //   this.phaseService.showV2RegisterPopup = false;
          //   this.router.navigate(['homev2']);
          // }
          if (
            this.phaseService.conditionToCreateAndSyncBuildCardToBNow() &&
            this.dataService.user &&
            !this.dataService.user.currency
          ) {
            this.phaseService.showRegister = true;
            this.phaseService.openCommonAuth = true;
            this.phaseService.signupNextStep = false;
            this.phaseService.signUpCurrencyForBNow = true;
          }
          if (this.phaseService.gotoCreateNew) {
            this.phaseService.gotoCreateNew = false;
            this.emptyBuildCard();
            this.phaseService.showSaveProgress = false;
            this.dataService.unsavedChanges = false;
            this.phaseService.selectedApps = [];
            this.router.navigate([this.dataService.firstPage]);
          }
          let type = "single";
          if (
            this.homeTemplateService.activeFilters.category.length > 0 ||
            this.homeTemplateService.activeFilters.price.length > 0
          ) {
            type = "filter_sort";
          } else if (this.homeTemplateService.sortingOption) {
            type = "sorting";
          }
          this.homeTemplateService.clearFilter("price", true);
          this.homeTemplateService.clearSearch();
          this.homeTemplateService.currencyChange.next({
            currencyId: this.dataService.user.currency.id,
            type: type,
            filters: this.homeTemplateService.activeFilters,
            sorting: this.homeTemplateService.sortingOption,
          });
          this.renderer.removeClass(document.body, "modal-open");
          this.renderer.removeClass(
            document.getElementById("app-component"),
            "hide-scroll-bar"
          );
        }, 100);
      },
      (error) => {
        const err = error.error;
        if (err.errors && err.errors.email) {
          this.ErrorMsg = "Email " + err.errors.email[0];
        }
        this.preventClick = false;
        this.phaseService.checkExitIntentTimer();
        this.registerButtonClass = "errorAnimation";
        this.phaseService.showV2SignUpPopup = false;
        this.phaseService.showV2RegisterPopup = false;
        this.signUpButtonClicked = false;
      }
    );
    // }
  }

  getBuildCardData(uniqCode) {
    this.apiRequest
      .fetchSingleBuildCardData(uniqCode)
      .subscribe((data: any) => {
        if (this.appDataService.urlParameters.upgrade_to_starter) {
          this.apiRequest
            .upgradeToStarter(
              uniqCode,
              this.appDataService.urlParameters.upfront
            )
            .subscribe((res: any) => {
              this.phaseService.installmentID = res.data.id;
              this.phaseService.depositAmount =
                res.data.attributes.installment_amount;
              this.getPaymentUrl(res.data.attributes.payment_data);
            });
        } else if (this.appDataService.urlParameters.upgrade_to_store) {
          this.apiRequest
            .upgradeToStore(uniqCode, this.appDataService.urlParameters.upfront)
            .subscribe((res: any) => {
              this.getPaymentUrl(res.data.attributes);
              this.phaseService.installmentID = res.data.id;
              this.phaseService.depositAmount =
                res.data.attributes.installment_amount;
            });
        }
      });
  }

  getPaymentUrl(data) {
    this.phaseService.inProgressBuildCardId = data.payment_data.build_card_id;
    let paymentData: any = {
      apptoken: this.dataService.appHeaders.PAYMENT_APP_TOKEN,
      payment_data: {

        billing_address_attributes: {
          billing_address: this.dataService.user.address.address,
          billing_city: this.dataService.user.address.city,
          billing_name:
            this.dataService.user.address.first_name +
            " " +
            this.dataService.user.address.last_name,
          billing_state: this.dataService.user.address.state,
          billing_tel: this.dataService.user.address.phone,
          billing_zip: this.dataService.user.address.pincode,
        },
        billing_entity: this.dataService.user.billing_entity,
        capture: "true",
        card_notify_url:
          environment.API_URL +
          "payment_billing/builder_card_owners/card_status",
        currency:
          this.dataService.buildCardData && this.dataService.buildCardData.id
            ? this.dataService.buildCardData.currency.code
            : this.dataService.user.currency.code,
        description: data.payment_data.description,
        email: this.dataService.user.email,
        frontend_redirect_url: "",
        payment_gateway:
          this.dataService.user && this.dataService.user.currency.code === "INR"
            ? "ccavenue"
            : "stripe",
        payment_mode: "complete",
        payment_notify_url:
          environment.API_URL + "payment_billing/installments/status",
        redirect_billing_details: `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/billingdetails`,
        redirect_dashboard_url: window.location.origin + "/dashboard",
        redirect_payment_plan: `${window.location.origin}/buildcard-payment/${this.dataService.buildCardData.uniq_code}/paymentplan`,
        redirect_url: "",
        source: data.payment_data.source,
        installment_type: data.payment_data.installment_type,
        build_card_id: data.payment_data.build_card_id,
        build_card_name: data.payment_data.build_card_name,
        frequency: data.payment_data.frequency,
        installment_amount: data.payment_data.installment_amount,
        installment_count: data.payment_data.installment_count,
        source_model_guid: data.id,
        source_model_name: "Installment",
      },
    };
    this.apiRequest.sendPaymentDetailsAndPoll(paymentData).subscribe((res) => {
      this.phaseService.startPollingForPayment();
      this.dataService.showHideLoader(false);
    });
  }

  public signupPartner() {
    this.preventClick = true;
    this.partnerApiService
      .registerPartner(this.signup_data)
      .finally(() => {
        this.preventClick = false;
      })
      .subscribe(
        (data) => {
          this.registerButtonClass = "successAnimation";
          this.phaseService.hidesignupPopup();
          this.partnerDataService.partnerSignupData = data.builder_partner;
          this.phaseService.showRegister = false;
          this.phaseService.openCommonAuth = false;
          this.partnerDataService.isPartnerVerified = true;
          this.showVerificationMessage = true;
          this.resetSignupForm();
        },
        (error) => {
          this.ErrorMsg = "Email " + error.error.errors.email[0];
          this.preventClick = false;
          this.registerButtonClass = "errorAnimation";
        }
      );
  }

  public goToVerificationMessage() {
    this.showVerificationMessage = true;
    this.partnerResendClicked = false;
    this.ErrorMsg = null;
  }

  registrationNameChanged(event) {
    this.checkNameValidOnClick = false;
    // this.checkNumberValidOnClick = false;
    // this.checkPwdValidOnClick = false;
  }

  registrationMobileChanged(event) {
    // this.checkNameValidOnClick = false;
    this.checkNumberValidOnClick = false;
    // this.checkPwdValidOnClick = false;
    if (this.signupNextStepNumberInitiated === 0) {
      this.signupNextStepNumberInitiated++;
    }
  }

  registrationPwdChanged(event) {
    // this.checkNameValidOnClick = false;
    // this.checkNumberValidOnClick = false;
    this.checkPwdValidOnClick = false;
    if (this.signupNextStepPwdInitiated === 0) {
      this.signupNextStepPwdInitiated++;
    }
  }

  public triggerSignup() {
    if (this.dataService.currentPage === "home") {
      this.homeTemplateService.createBuildCardPending = true;
    }
    this.onFocus = this.checkIfMobileValid();
    this.checkPartnerEmail = false;
    this.checkNameValidOnClick = false;
    this.checkNumberValidOnClick = false;
    this.checkPwdValidOnClick = false;
    this.checkTandCValidOnClick = false;
    this.ErrorMsg = "";
    this.registerButtonClass = "";
    this.isSubmitted = true;
    if (this.checkIfEmailValid(false, true)) {
      this.checkPartnerEmail = true;
      return;
    } else {
      this.checkPartnerEmail = false;
    }
    if (this.checkFirstNameValid()) {
      this.checkNameValidOnClick = true;
      return;
    } else {
      this.checkNameValidOnClick = false;
    }
    if (this.checkIfMobileValid()) {
      this.checkNumberValidOnClick = true;
      return;
    } else {
      this.checkNumberValidOnClick = false;
    }
    if (this.isPasswordError()) {
      this.checkPwdValidOnClick = true;
      return;
    } else {
      this.checkPwdValidOnClick = false;
    }
    if (!this.selectedCurrency) {
      return;
    }
    if (!this.signupTerm2 && !this.phaseService.isPartnerDashboard()) {
      this.checkTandCValidOnClick = true;
      return;
    } else {
      this.checkTandCValidOnClick = false;
    }

    if (this.checkSignupValidity() && this.isSubmitted) {
      this.registerButtonClass = "errorAnimation";
      return;
    } else if (!this.checkSignupValidity() && this.isSubmitted) {
      setTimeout(() => {
        if (this.phaseService.isPartnerDashboard()) {
          this.signupPartner();
        } else {
          this.signup_click(this.registerForm);
        }
      }, 500);
    }
  }

  loginEmailChanged(event) {
    this.showEmailError = false;
    // this.showPwdError = false;
    this.loginButtonClass = "";
    this.showServerError = false;
  }

  loginPwdChanged(event) {
    // this.showEmailError = false;
    this.showPwdError = false;
    this.loginButtonClass = "";
    this.showServerError = false;
  }

  public triggerLogin() {
    if (this.dataService.currentPage === "home") {
      this.homeTemplateService.createBuildCardPending = true;
    }
    this.loginButtonClass = "";
    this.isLogginButtonClicked = true;
    this.phaseService.showSpinner = false;
    this.shouldValidateEmail = true;
    this.shouldValidatePassword = true;

    if (this.checkSigninValidity()) {
      setTimeout(() => {
        this.loginButtonClass = "errorAnimation";
      }, 100);

      if (this.checkIfEmailValid(true)) {
        this.checkValidEmailOnLogin = true;
        this.showEmailError = true;
        return;
      }
      if (this.isPasswordError(true)) {
        this.checkValidPwdOnLogin = true;
        this.showPwdError = true;
        return;
      }
      return;
    } else {
      if (this.checkIfEmailValid(true)) {
        this.checkValidEmailOnLogin = true;
        this.showEmailError = true;
        return;
      }
      if (this.isPasswordError(true)) {
        this.checkValidPwdOnLogin = true;
        this.showPwdError = true;
        return;
      }
      if (this.phaseService.isPartnerDashboard()) {
        this.loginPartner();
      } else {
        this.login_click(this.loginForm);
      }
    }
  }

  public loginPartner() {
    this.ErrorMsg = "";
    this.preventClick = true;
    this.partnerApiService.loginPartner(this.login_data).subscribe(
      (data) => {
        this.loginButtonClass = "successAnimation";
        this.phaseService.hidesignupPopup();
        this.preventClick = false;
        this.partnerDataService.partnerData = data;
        if (this.loginForm) {
          this.closeSignIn(null);
        }
        if (
          this.partnerDataService.partnerData &&
          this.partnerDataService.partnerData.is_verified
        ) {
          this.partnerDataService.setPartnerUser(
            this.partnerDataService.partnerData
          );
          this.appDataService.handlePartnerRoutes();
        } else {
          this.partnerDataService.isPartnerVerified = true;
          this.showVerificationMessage = true;
        }
      },
      (error) => {
        this.showServerError = true;
        this.loginButtonClass = "errorAnimation";
        this.preventClick = false;
        this.ErrorMsg = "Invalid username/password";
      }
    );
  }

  resendEmailChanged() {
    this.showResendEmailError = false;
  }

  public resendVerificationLinkToPartner() {
    this.ErrorMsg = null;
    this.showResendEmailError = true;
    this.resendVerificationClass = "";
    if (this.verification_email.errors) {
      setTimeout(() => {
        this.resendVerificationClass = "errorAnimation";
        return;
      }, 100);
    } else {
      this.resendVerificationClass = "successAnimation";
    }

    if (this.resetData.email) {
      this.preventClick = true;
      this.partnerApiService
        .resendVerificationLink(this.resetData.email)
        .subscribe(
          (data) => {
            this.preventClick = false;
            this.successMsg = data.data;
            this.errorMessage = null;
          },
          (error) => {
            this.preventClick = false;
            this.successMsg = null;
            this.ErrorMsg = error.error.message;
            this.resendVerificationClass = "errorAnimation";
          }
        );
    }
  }

  public openVerificationForm() {
    this.partnerResendClicked = true;
    this.showVerificationMessage = false;
  }

  public closeCommonAuthPopup(navigateToHome?) {
    this.appDataService.urlParameters.redirection_url = "";
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "sign_in_modal_closed",
      {
        user_id: this.dataService.user?.id,
      }
    );
    this.phaseService.emailConfirmedPoupup = false;
    this.phaseService.userAlreadyVeriied = false;
    this.phaseService.openCommonAuth = false;
    this.phaseService.showSpinner = false;
    this.dataService.showHideLoader(false);
    if (this.dataService.couponObject) {
      if (this.comparePromotion()) {
        this.phaseService.promotionValid = false;
        this.phaseService.selectedHash.promotion_id = null;
        this.phaseService.manualPromoCode = null;
        this.dataService.manualPromotion = null;
        this.dataService.homePageData.promotion = null;
        this.phaseService.popupPromocode = null;
      }
      this.phaseService.showSignin = false;
      this.phaseService.showRegister = false;
      this.phaseService.showForgotPassword = false;
      this.phaseService.showPromobar = true;
      this.phaseService.popupCoupon = false;
      this.phaseService.clickedpopupsignin = false;
      this.phaseService.isAppDiscountApplied = false;
      this.phaseService.AppDiscountSuccessfullyApplied = false;
    }
    if (
      (navigateToHome && !this.dataService.currentPage) ||
      this.dataService.shareUrlData ||
      this.dataService.currentPage === "dashboard" ||
      this.appDataService.urlParameters.admin_access
    ) {
      this.router.navigate(["home"]);
    }
    if (
      this.appDataService.urlParameters.red === "meet_now" ||
      this.appDataService.urlParameters.red === "ip_now"
    ) {
      this.dataService.showMeetNowOverloading = true;
    }
    if (this.dataService.openMeetNowModal) {
      this.dataService.openMeetNowModal = false;
      this.dataService.showMeetNowModel = false;
    }
  }

  public comparePromotion() {
    if (this.dataService.couponObject && this.dataService.couponObject.code) {
      let promotion =
        this.dataService.homePageData.promotion ||
        this.dataService.manualPromotion;
      if (promotion && promotion.code) {
        if (promotion.code === this.dataService.couponObject.code) {
          return true;
        }
      }
    }
    return false;
  }

  public closeSignIn(form, fromCancel?) {
    this.phaseService.signupNextStep = false;
    this.phaseService.showPromobar = false;
    this.phaseService.clickedsignupPopup = true;

    if (!this.preventClick) {
      this.phaseService.progressSubUrl = "";
      this.dataService.sendToProjectProgress = false;
      this.appDataService.urlParameters.signup_stage = "";
      this.phaseService.showSignin = false;
      this.phaseService.showRegister = false;
      this.phaseService.showForgotPassword = false;
      this.phaseService.isSpecingFlow = false;
      this.phaseService.isNdaFlow = false;
      this.isOtpFormVisible = false;
      this.dataService.loginNewModule = false;
      if (!this.dataService.showCurrencyAfterLogin) {
        this.phaseService.reachedUptoPhases = false;
      }
      this.dataService.toDashBoard = false;
      this.ErrorMsg = "";
      if (form) {
        form.resetForm();
      }
      this.countryCode = 1;
      this.checkingEmailValid = false;
      this.validationStarted = false;
      this.isEmailValid = false;
      this.resetSignupForm();
      if (this.dataService.isBuildCardUrl && fromCancel) {
        this.dataService.isBuildCardUrl = false;
        this.dataService.buildCardLink = "";
        this.router.navigate(["home"]);
      }
    }
    if (this.phaseService.conditionToCreateAndSyncBuildCardToBNow()) {
      this.appDataService.urlParameters.red = null;
      this.appDataService.urlParameters.application = null;
    }
  }

  public social_sign(provider) {
    // event.preventDefault();
    this.isSocialSignClicked = true;
    this.resetSigninForm();
    this.auth.signOut();
    this.homeTemplateService.createBuildCardPending = true;
    if (provider === "google") {
      this.auth.signIn(GoogleLoginProvider.PROVIDER_ID);
    } else if (provider === "facebook") {
      this.auth.signIn(FacebookLoginProvider.PROVIDER_ID);
    } else {
      this.linkedInSignUp(provider);
    }
    this.socialSignAuthState(provider);
  }

  linkedInSignUp(provider) {
    let redirect_url = "";
    redirect_url = encodeURIComponent(window.location.origin);
    window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=861rl9xi4ox6g2&redirect_uri=${redirect_url}/&scope=openid%20profile%20email`
    );
    const timer = setInterval(() => {
      const code = localStorage.getItem("code");
      if (code) {
        clearInterval(timer);
        this.getLinkedInUser(code);
      }
    }, 5000);
  }

  getLinkedInUser(code) {
    const userData = {};
    userData["code"] = code;
    userData["redirect_uri"] = window.location.origin + '/';
    userData["utm_campaign"] = this.appDataService.urlParameters.utm_campaign ? this.appDataService.urlParameters.utm_campaign : this.dataService.getCookieForUTMparams('utmCampaign');
    userData["utm_source"] = this.appDataService.urlParameters.utm_source ? this.appDataService.urlParameters.utm_source : this.dataService.getCookieForUTMparams('utmSource');
    userData["utm_medium"] = this.appDataService.urlParameters.utm_medium ? this.appDataService.urlParameters.utm_medium : this.dataService.getCookieForUTMparams('utmMedium');
    userData["utm_term"] = this.dataService.getCookieForUTMparams('utmTerm');
    userData["utm_content"] = this.dataService.getCookieForUTMparams('utmContent');
    userData["utm_GCLID"] = this.dataService.getCookieForUTMparams('utmGCLID');
    this.apiRequest.linkedIn_user(userData).subscribe(
      (data) => {
        this.afterLogin(data, "linkedin");
      },
      (error) => {}
    );
  }

  public socialSignAuthState(provider) {
    this.dataService.socialSignUser = new SocialUser();
    this.sub = this.auth.authState.subscribe(
      (socialUser) => {
        if (this.dataService.socialSignUser.email) {
          return;
        }
        this.dataService.socialSignUser = socialUser;
        this.phaseService.SignUpProviders = provider;
        this.ErrorMsg = "";
        if (!this.dataService.user) {
          if (this.appDataService.urlParameters.inviteToken) {
            this.userData.inviteToken =
              this.appDataService.urlParameters.inviteToken;
          }
          this.userData.signup_stage = this.appDataService.urlParameters.signup_stage;
          this.userData.utm_campaign = this.appDataService.urlParameters.utm_campaign ? this.appDataService.urlParameters.utm_campaign : this.dataService.getCookieForUTMparams('utmCampaign');
          this.userData.utm_source = this.appDataService.urlParameters.utm_source ? this.appDataService.urlParameters.utm_source : this.dataService.getCookieForUTMparams('utmSource');
          this.userData.utm_medium = this.appDataService.urlParameters.utm_medium ? this.appDataService.urlParameters.utm_medium : this.dataService.getCookieForUTMparams('utmMedium');
          this.userData.utm_term = this.appDataService.urlParameters.utm_term ? this.appDataService.urlParameters.utm_term : this.dataService.getCookieForUTMparams('utmTerm');
          this.userData.utm_content = this.appDataService.urlParameters.utm_content ? this.appDataService.urlParameters.utm_content : this.dataService.getCookieForUTMparams('utmContent');
          this.userData.utm_GCLID = this.dataService.getCookieForUTMparams('utmGCLID');
          const user = Object.assign(
            this.userData,
            this.dataService.socialSignUser
          );
          this.apiRequest.login_signup(user, "login").subscribe((subdata) => {
            if (this.appDataService.urlParameters.email_confirm_token) {
              const data = {
                token: this.appDataService.urlParameters.email_confirm_token,
              };
              this.apiRequest.emailConfirm(data).subscribe((resp: any) => {
                this.phaseService.emailConfirmedPoupup = true;
                if (resp.message == "User already verified") {
                  this.phaseService.userAlreadyVeriied = true;
                }
              });
            }
            this.zone.run(() => {
              this.afterLogin(user, provider);
            });
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  afterLogin(user, provider) {
    this.renderer.removeClass(document.body, "modal-open");
    this.renderer.removeClass(
      document.getElementById("app-component"),
      "hide-scroll-bar"
    );
    if (this.appDataService.urlParameters.schedular_reason) {
      this.showSchedular();
    }
    if (this.phaseService.SignUpProviders) {
      if (this.isShowLoginPopup()) {
        this.SignUpSocialPlatform =
          this.phaseService.SignUpProviders === "google"
            ? "Google SignIn"
            : this.phaseService.SignUpProviders === "facebook"
            ? "Facebook SignIn"
            : "Linkedin SignIn";
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "sign_in_successful",
          {
            platform_source: this.phaseService.SignUpProviders,
            screen_source: "Studio Pro",
            user_id: this.dataService.user?.id,
          }
        );
      } else {
        this.SignUpSocialPlatform =
          this.phaseService.SignUpProviders === "google"
            ? "Google SignUp"
            : this.phaseService.SignUpProviders === "facebook"
            ? "Facebook SignIn"
            : "Linkedin SignIn";
        if (this.appDataService.urlParameters.is_freemium) {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            "freemium_new_user_created",
            {}
          );
        }
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "sign_up_successful",
          {
            current_url: window.location.href,
            platform_source: this.phaseService.SignUpProviders,
            screen_source: "Studio Pro",
            user_id: this.dataService.user?.id,
          }
        );
        this.phaseService.trackEventForObject(
          this.analyticsSegment,
          "sign_in_successful",
          {
            platform_source: this.phaseService.SignUpProviders,
            screen_source: "Studio Pro",
            user_id: this.dataService.user?.id,
          }
        );
      }
    }

    if (!this.appDataService.urlParameters.cpe_user && this.appDataService.urlParameters.redirection_url) {
      this.redirectToDestination();
    }

    if (
      Object.keys(this.dataService.user).length &&
      !this.dataService.user.currency
    ) {
      this.isSocialSignUp = true;
      return;
    }
    if (
      this.homeTemplateService.createBuildCardPending &&
      this.phaseService.selectedApps &&
      this.phaseService.selectedApps.length > 0
    ) {
      // this.createBuildCardAfterLoginSignUp();
    }
    this.phaseService.openCommonAuth = false;
    this.phaseService.fetchUserShowData();
    this.phaseService.AppDiscountSuccessfullyApplied =
      this.phaseService.isAppDiscountApplied;
    if (
      this.phaseService.AppDiscountSuccessfullyApplied ||
      this.phaseService.clickedpopupsignin
    ) {
      this.phaseService.popupCoupon = true;
    }
    if (this.phaseService.popupCoupon) {
      this.phaseService.popupCouponApplied = true;
      this.signupGetCode();
    }
    this.phaseService.promotionCurrencySubject.next(true);
    if (this.dataService.homePageData.currency) {
      this.phaseService.specing_price =
        this.dataService.homePageData.currency.specing_price;
    }
    this.phaseService.trackSocialSigninEvent(provider);
    if (this.dataService.user && this.dataService.user.source === "vads") {
      window.location.href = "http://vads.engineer.ai";
    }
    let type = "single";
    if (
      this.homeTemplateService.activeFilters.category.length > 0 ||
      this.homeTemplateService.activeFilters.price.length > 0
    ) {
      type = "filter_sort";
    } else if (this.homeTemplateService.sortingOption) {
      type = "sorting";
    }
    this.homeTemplateService.clearFilter("price", true);
    this.homeTemplateService.clearSearch();
    this.homeTemplateService.currencyChange.next({
      currencyId: this.dataService.user.currency.id,
      type: type,
      filters: this.homeTemplateService.activeFilters,
      sorting: this.homeTemplateService.sortingOption,
    });
    if (this.phaseService.selectedHash.cloud_price > 0) {
      this.phaseService.convertCloudPriceBasedOnUserCurrency();
    }

    if (this.phaseService.loadDashBoard) {
      this.phaseService.loadDashBoard = false;
      this.phaseService.loadDashBoardData(this.analyticsSegment);
    } else if (this.phaseService.gotoCreateNew) {
      this.phaseService.gotoCreateNew = false;
      this.emptyBuildCard();
      this.phaseService.showSaveProgress = false;
      this.dataService.unsavedChanges = false;
      this.phaseService.selectedApps = [];
      this.router.navigate([this.dataService.firstPage]);
    } else if (this.dataService.meetNowScheduleCall) {
      this.dataService.meetNowScheduleCall = false;
      //this.dataService.hideExitIntentPopUp = true;
      this.phaseService.createNormallOrInstantSpecCall("normal");
    } else if (this.dataService.meetNowConnectCall) {
      this.dataService.meetNowConnectCall = false;
      this.dataService.isAfterAuthentication = true;
      this.phaseService.createNormallOrInstantSpecCall("instant");
    } else if (this.phaseService.isNdaFlow) {
      this.phaseService.isNdaFlow = false;
      this.fetchDocusignUrl();
    } else if (this.phaseService.isSpecingFlow) {
      this.phaseService.isSpecingFlow = false;
      this.router.navigate(["feature"]);
    } else if (this.dataService.toDashBoard) {
      this.router.navigate(["dashboard"]);
    } else if (this.dataService.isBuildCardUrl) {
      this.router.navigate([this.dataService.buildCardLink]);
    } else if (
      this.appDataService.urlParameters.red === "meet_now" ||
      this.appDataService.urlParameters.red === "ip_now" ||
      this.dataService.openMeetNowModal
    ) {
      this.dataService.showMeetNowModel = true;
    } else if (this.phaseService.conditionToCreateAndSyncBuildCardToBNow()) {
      this.dataService.showHideLoaderForBNow(true);
      this.apiRequest
        .createAndSyncBuildCardToNow(
          this.appDataService.urlParameters.application
        )
        .subscribe(
          (data: any) => {
            this.appDataService.urlParameters.red = null;
            this.appDataService.urlParameters.application = null;
            this.dataService.showHideLoaderForBNow(false);
            window.open(
              this.dataService.homePageData.builder_partner.builder_now_url +
                "/" +
                data.id +
                "?red=now",
              "_blank"
            );
          },
          (error) => {
            this.appDataService.urlParameters.red = null;
            this.appDataService.urlParameters.application = null;
            this.dataService.showHideLoaderForBNow(false);
          }
        );
    } else if (this.appDataService.urlParameters.red === "now") {
      window.location.href =
        this.dataService.homePageData.builder_partner.builder_now_url +
        "/createbuildcard" +
        (this.appDataService.urlParameters.prototype_promo
          ? "?prototype_promo=" +
            this.appDataService.urlParameters.prototype_promo
          : "");
    }
    // else if (this.appDataService.urlParameters.v2 && this.appDataService.urlParameters.v2 === '1') {
    //   this.router.navigate(['homev2']);
    // }
    else {
      this.saveData();
      if (
        this.dataService.openMeetNowModal &&
        !this.dataService.checkIfCPEUser()
      ) {
        this.dataService.showMeetNowModel = true;
      }
    }
    const promoValid = this.phaseService.isValidPromotion();
    this.setUrlpromotion(promoValid);
    if (this.phaseService.selectedPhases.length > 0) {
      this.phaseService.updatePrice();
    }
    this.preventClick = false;
    this.phaseService.isValidPromotion();
    this.closeSignIn(null);
  }
  checkUserHasCurrency() {
    if (this.dataService.user && !this.dataService.user.currency) {
      this.homeTemplateService.createBuildCardPending = true;
      this.isSocialSignUp = true;
    }
  }

  createAccountThroughSocialAuth() {
    this.isSubmitted = true;
    this.isCreateAccountWithAuth = true;
    if (!this.selectedCurrency || this.checkIfPhoneInValid()) {
      return;
    }
    if (!this.signupTerm2) {
      return;
    }
    if (this.checkIfValidSocialemail()) {
      this.checkValidEmailOnLogin = false;
      return;
    }

    this.phaseService.selectedCurrency = this.selectedCurrency;
    this.socialSignup.phone_number = this.socialSignup.phone_number
      ? this.socialSignup.phone_number.replace(
          this.dataService.countryNameCode,
          this.dataService.countryNameCode + "-"
        )
      : this.socialSignup.phone_number;
    this.phaseService.closeCurrencyChooserForUser(
      this.socialSignup.phone_number,
      this.socialSignup.email
    );
    if (!this.dataService.user.bant_answers && !this.appDataService.urlParameters.is_dls_store_flow) {
      this.dataService.showMeetNowModel = true;
    }
    if (this.homeTemplateService.createBuildCardPending) {
      // this.createBuildCardAfterLoginSignUp();
    }
  }

  public forgot_password(form: FormGroup) {
    this.showEmailError = false;
    this.showPwdError = false;
    if (!this.preventClick) {
      this.phaseService.showForgotPassword = true;
      this.phaseService.showSignin = false;
      this.showForgotPassword = true;
      this.resetData.email = "";
      this.ErrorMsg = "";
      form.reset();
      this.setPageTitleAndTrack();
      this.setIndex = 0;
    }
  }

  public closeForgot(form: FormGroup) {
    if (!this.preventClick) {
      this.appDataService.urlParameters.signup_stage = "";
      this.phaseService.showForgotPassword = false;
      this.showForgotPassword = false;
      this.ErrorMsg = "";
      if (form !== null) {
        form.reset();
      }
    }
  }

  forgotEmailSelection(event) {
    this.showForgotEmailError = false;
  }

  public send_email(form: FormGroup) {
    this.ErrorMsg = "";
    this.showForgotEmailError = true;
    this.sendButtonClass = "";
    this.isSendClicked = true;
    if (this.checkIfForgetEmailValid()) {
      setTimeout(() => {
        this.sendButtonClass = "errorAnimation";
        this.checkOnForgotEmail = true;
        return;
      }, 100);
    } else {
      this.checkOnForgotEmail = false;
      if (!this.preventClick) {
        this.preventClick = true;
        this.apiRequest.login_signup(this.resetData, "forgot").subscribe(
          (data: any) => {
            this.sendButtonClass = "successAnimation";
            setTimeout(() => {
              form.reset();
              this.success_error_Msg = data.message;
              this.showForgotPassword = false;
              this.ErrorMsg = "";
              this.preventClick = false;
            }, 100);
          },
          (error) => {
            if (error.error.errors.email !== null) {
              this.ErrorMsg = "Email " + error.error.errors.email;
            } else {
              this.ErrorMsg = error.message;
            }
            this.preventClick = false;
            this.sendButtonClass = "errorAnimation";
          }
        );
      }
    }
  }

  public goToLogin(form: FormGroup) {
    if (!this.preventClick) {
      this.closeForgot(null);
      this.ErrorMsg = "";
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      if (form) {
        form.reset();
      }
    }
  }

  public setCurrencyForSellTo() {
    if (
      this.dataService.homePageData.builder_partner &&
      this.dataService.homePageData.builder_partner.partner_type === "sell_to"
    ) {
      this.dataService.user.currency =
        this.dataService.homePageData.builder_partner.currencies[0];
      if (this.dataService.homePageData.currency) {
        this.phaseService.specing_price =
          this.dataService.user.currency.specing_price;
      }
      this.dataService.showHideLoader(true);
      this.apiRequest
        .updateProfile(this.dataService.user, this.dataService.user.currency.id)
        .subscribe((data: any) => {
          this.saveData();
          this.dataService.showHideLoader(false);
        });
    }
  }

  public saveData() {
    if (this.dataService.user && this.dataService.user.currency) {
      if (
        !this.dataService.isFromAppDetails &&
        this.dataService.currentPage === "templateDetail" &&
        (this.phaseService.checkIfRentalPricingModal() ||
          this.phaseService.isRentalBuildCard())
      ) {
        this.apiRequest
          .fetchTemplateDetails(
            this.phaseService.rentalTemplateAlias,
            false,
            true
          )
          .subscribe((data: any) => {
            this.dataService.appDetails = this.dataService.templateDetailModel;
            this.phaseService.selectedApps = [];
            this.toggleSelectedAppsById(this.dataService.appDetails);
          });
      } else if (this.dataService.isFromAppDetails) {
        if (
          this.phaseService.checkIfRentalPricingModal() &&
          !this.appDataService.urlParameters.cpe_user &&
          !this.phaseService.isPayNowClickedForRental
        ) {
          this.dataService.showHideLoader(true);
        } else {
          this.phaseService.showProjectNamePopup =
            !this.dataService.isDirectCheckOut;
          this.dataService.showDeliveryLoading = true;
        }
        this.phaseService.setDataForFeaturePlatformAndTeam();
      } else if (this.phaseService.reachedUptoPhases) {
        this.phaseService.buildCardProcessAfterSignUp();
      } else if (this.dataService.isFromViewPrototype) {
        this.phaseService.redirectToNowForPrototype(
          this.dataService.signupSrcName
        );
      } else if (this.dataService.comingViewPrototype) {
        if (this.dataService.checkIfCPEUser()) {
          this.phaseService.redirectToNowForPrototype(
            this.dataService.signupSrcName
          );
        } else {
          this.phaseService.trackEventForObject(
            this.analyticsSegment,
            "prototype_selected",
            {
              user_id: this.dataService.user?.id,
              screen_source: this.dataService.currentPage,
            }
          );
          window.open(
            this.dataService.homePageData.builder_partner.builder_now_url +
              "/wip",
            "_blank",
            "noopener"
          );
        }
      }
    } else {
      this.dataService.showCardPopUpAfterCurrencySetup = true;
    }
  }

  public showPasswordlogin() {
    this.loginPass.show = !this.loginPass.show;
    if (this.loginPass.show) {
      this.loginPass.text = "hide";
    } else {
      this.loginPass.text = "show";
    }
  }

  public showPasswordRegister() {
    this.registerPass.show = !this.registerPass.show;
    if (this.registerPass.show) {
      this.registerPass.text = "hide";
    } else {
      this.registerPass.text = "show";
    }
  }

  public ngAfterContentChecked(): void {
    if (this.loginScreen != null) {
      if (
        this.isVisible === false &&
        this.loginScreen.nativeElement.offsetParent != null
      ) {
        this.isVisible = true;
      } else if (
        this.isVisible === true &&
        this.loginScreen.nativeElement.offsetParent === null
      ) {
        this.isVisible = false;
      }
    }
  }

  public resetSignupForm() {
    this.login_data = { email: "", password: "", invite_token: null };
    this.signup_data = {
      first_name: null,
      last_name: "",
      email: null,
      phone_number: "",
      password: null,
      invite_token: null,
    };
    this.signupTerm1 = false;
    this.signupTerm2 = false;
    this.phoneNumber = "";
    this.phaseService.showRegister = false;
    this.phaseService.openCommonAuth = false;
    this.isOtpFormVisible = false;
    this.dataService.loginData = null;
    this.errorMessage = null;
    this.ErrorMsg = null;
  }

  get signUpEvent() {
    const signUpEvent = this.appDataService.urlParameters.signup_stage;
    if (signUpEvent === "home") {
      return "login_abhomescreen";
    } else if (signUpEvent === "features") {
      return "login_abfeatures";
    } else if (signUpEvent === "buildphases") {
      return "login_abphases";
    } else {
      return "login_" + this.dataService.currentPage;
    }
  }

  get signUpLoginCloseEvent() {
    const signUpLoginCloseEvent =
      this.appDataService.urlParameters.signup_stage;
    if (signUpLoginCloseEvent === "home") {
      return "login_homescreen";
    } else if (signUpLoginCloseEvent === "features") {
      return "login_features";
    } else if (signUpLoginCloseEvent === "apps") {
      return "login_apps";
    } else if (signUpLoginCloseEvent === "buildphases") {
      return "login_phases";
    } else if (signUpLoginCloseEvent === "ab_home") {
      return "login_abhomescreen";
    } else if (signUpLoginCloseEvent === "ab_features") {
      return "login_abfeatures";
    } else if (signUpLoginCloseEvent === "ab_phases") {
      return "login_abphases";
    } else {
      return "login_" + this.dataService.currentPage;
    }
  }

  public resetSigninForm() {
    this.login_data = { email: "", password: "", invite_token: null };
    this.fullName = "";
    this.signup_data = {
      first_name: null,
      last_name: "",
      email: null,
      phone_number: "",
      password: null,
      invite_token: null,
    };
    if (
      this.dataService.countryNameCode &&
      !this.dataService.countryNameCode.includes("+")
    ) {
      this.dataService.countryNameCode = "+" + this.dataService.countryNameCode;
    }
    this.signup_data.phone_number = this.dataService.countryNameCode;
    this.errorMessage = null;
    this.resetErrorMsgsOnSignInScreen();
    this.resetErrorMsgsOnSignupScreen();
  }

  public setSignupAnalytics(field, provider?, currency?) {
    if (this.phaseService) {
      this.phaseService.setSignupAnalytics(field, provider, currency);
    }
  }

  public onFlagChange() {
    if (
      this.mobileNumber &&
      this.mobileNumber.valid &&
      this.mobileNumber.valueAccessor
    ) {
      this.phaseService.countryNameCode =
        this.mobileNumber.valueAccessor.selectedCountry.countryCode;
      this.dataService.countryNameCode =
        this.mobileNumber.valueAccessor.selectedCountry.dialCode;
    }
  }

  public signupStepNext() {
    this.checkEmailError = true;
    this.signUpNextButtonClass = "";
    if (this.signup_data.email) {
      this.signup_data.email = this.signup_data.email
        ? this.signup_data.email.toLowerCase()
        : "";
    }
    if (!this.phaseService.signupNextStep) {
      this.isNextClicked = true;
    } else {
      this.isNextClicked = false;
    }
    this.toCheck = true;
    this.isEmailValid = true;
    this.isEmailExist = false;
    if (this.checkIfEmailValid(false)) {
      setTimeout(() => {
        this.signUpNextButtonClass = "errorAnimation";
        return;
      }, 100);
    } else {
      setTimeout(() => {
        this.signUpNextButtonClass = "errorAnimation";
        this.phaseService.AppDiscountSuccessfullyApplied =
          this.phaseService.isAppDiscountApplied;
        if (
          this.phaseService.AppDiscountSuccessfullyApplied ||
          this.phaseService.clickedpopupsignin
        ) {
          this.phaseService.popupCoupon = true;
        }
        this.checkEmailRegistered();
      }, 100);
    }
    this.signupNextStepIndex = 0;
    this.signupNextStepNumberInitiated = 0;
    this.signupNextStepPwdInitiated = 0;
    if (this.signup_email && !this.signup_email.errors) {
      this.phaseService.parseUserProperties({
        email: this.signup_email.value,
        utm_campaign: this.appDataService.urlParameters.utm_campaign,
        utm_source: this.appDataService.urlParameters.utm_source,
        utm_medium: this.appDataService.urlParameters.utm_medium,
        utm_term: this.appDataService.urlParameters.utm_term,
        utm_content: this.appDataService.urlParameters.utm_content,
        traits: {
          email: this.signup_email.value,
        },
      });
    }
  }

  public checkEmailRegistered() {
    this.apiRequest
      .checkEmailRegistered(encodeURIComponent(this.signup_data.email))
      .subscribe(
        (data) => {
          if (data.message) {
            this.isEmailExist = true;
            this.login_data.email = this.signup_data.email;
            if (
              !this.appDataService.urlParameters.email &&
              !this.appDataService.urlParameters.name &&
              !this.appDataService.urlParameters.phone_number
            ) {
              this.getUsersNameWithClearBitAPI();
            }
            this.phaseService.signupNextStep =
              !this.isEmailExist && this.isEmailValid;
            this.phaseService.showRegister = !this.isEmailExist;
            this.phaseService.showSignin = this.isEmailExist;
            if (this.phaseService.showV2SignUpPopup) {
              this.phaseService.showV2LoginPopup = this.isEmailExist;
              this.phaseService.showV2RegisterPopup = !this.isEmailExist;
            }
            this.phaseService.openCommonAuth = true;
            this.toCheck = false;
            // this.signup_data.phone_number = '';
            this.phaseService.countryNameCode = this.dataService.country_code
              ? this.dataService.country_code.toLowerCase()
              : "";
            this.isShowLoginPopup();
            this.onFlagChange();
          }
        },
        (error) => {
          this.phaseService.showPromobar = false;
          this.isEmailExist = false;
          this.phaseService.signupNextStep = !this.isEmailExist;
          if (
            !this.appDataService.urlParameters.email &&
            !this.appDataService.urlParameters.name &&
            !this.appDataService.urlParameters.phone_number
          ) {
            this.getUsersNameWithClearBitAPI();
          }
          this.phaseService.showRegister = !this.isEmailExist;
          this.phaseService.showSignin = this.isEmailExist;
          if (this.phaseService.showV2SignUpPopup) {
            this.phaseService.showV2LoginPopup = this.isEmailExist;
            this.phaseService.showV2RegisterPopup = !this.isEmailExist;
          }
          this.phaseService.openCommonAuth = true;
          this.toCheck = false;
          // this.signup_data.phone_number = '';
          this.phaseService.countryNameCode = this.dataService.country_code
            ? this.dataService.country_code.toLowerCase()
            : "";
          this.isShowLoginPopup();
          this.onFlagChange();
        }
      );
  }

  getUsersNameWithClearBitAPI() {
    this.apiRequest
      .clearBitApiCall(
        encodeURIComponent(this.signup_data.email),
        this.appDataService.getRequestOptionArgs("", false, true)
      )
      .subscribe(
        (resp) => {
          this.fullName =
            resp.person && resp.person.name && resp.person.name.fullName
              ? resp.person.name.fullName
              : "";
          this.signup_data.phone_number =
            resp.company && resp.company.phone ? resp.company.phone : "";
          this.signup_data.organisation =
            resp.company && resp.company.name ? resp.company.name : "";
        },
        (error) => {
          this.fullName = "";
          this.signup_data.phone_number = "";
          this.signup_data.organisation = "";
        }
      );
  }

  public goBackToInitialSignupStep() {
    this.phaseService.signupNextStep = false;
    this.checkNameValidOnClick = false;
    this.checkNumberValidOnClick = false;
    this.checkPwdValidOnClick = false;
  }

  addEmailHost(key: string) {
    this.isScrolling = true;
    if (this.signup_data.email.includes("@")) {
      this.signup_data.email = key;
      this.showEmailHosts = false;
    }
  }

  getEmailHosts() {
    let i = 0;
    const emailstr = this.signup_data.email.substring(
      0,
      this.signup_data.email.lastIndexOf("@") + 1
    );
    for (i = 0; i < this.emailHosts.length; i++) {
      this.hostEmails[i] = emailstr + this.emailHosts[i];
    }
  }

  emailHostSelection(event) {
    this.checkEmailError = false;
    // const key = event.keyCode;
    this.isScrolling = true;
    switch (event.keyCode) {
      case 40:
        if (this.arrowkeyLocation < this.emailHosts.length - 1) {
          this.arrowkeyLocation++;
        }
        break;
      case 38:
        if (this.arrowkeyLocation > 0) {
          this.arrowkeyLocation--;
        }
        break;
      case 8:
        this.arrowkeyLocation = -1;
        break;
      case 9:
        if (this.showEmailHosts) {
          if (this.arrowkeyLocation >= 0) {
            this.addEmailHost(this.copyHostEmails[this.arrowkeyLocation]);
          }
          this.isSelectionOnEnterKey = true;
          this.isScrolling = false;
          this.showEmailHosts = false;
        }
        break;
      case 13:
        if (!this.showEmailHosts) {
          this.signupStepNext();
        } else {
          if (this.arrowkeyLocation >= 0) {
            this.addEmailHost(this.copyHostEmails[this.arrowkeyLocation]);
          }
          this.isSelectionOnEnterKey = true;
          this.isScrolling = false;
          this.showEmailHosts = false;
        }
        break;
      default:
        break;
    }
  }

  focusInNumber(event) {
    this.onFocus = true;
  }
  @HostListener("document:click", ["$event.target"])
  onClick(targetElement) {
    if (this.divClicked) {
      const clickedInside =
        this.divClicked.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.showEmailHosts = false;
      }
    }
  }

  private setPageTitleAndTrack() {
    let title = "";
    if (this.isShowForgotPopup()) {
      title = Constants.PAGE_TITLES.forgot.code;
    } else if (this.isShowLoginPopup()) {
      title = Constants.PAGE_TITLES.apps.code;
    } else if (this.isShowSignupPopup()) {
      title = Constants.PAGE_TITLES.signup.code;
    }
    this.phaseService.setComponentTitle(this.analyticsSegment, title);
  }

  public checkIfExitIntentEmailValid(): boolean {
    return (
      this.exit_email &&
      !this.phaseService.validateEmail(this.signup_data.email)
    );
  }

  public signupGetCode() {
    if (
      !this.phaseService.popupPromocode &&
      this.dataService.homePageData.promotion.code
    ) {
      return;
    } else {
      if (this.dataService.couponObject) {
        this.phaseService.showPromobar = false;
        this.dataService.manualPromotion = this.dataService.couponObject;
        this.dataService.manualPromotion.code =
          this.dataService.couponObject.code;
        this.phaseService.promotionValid = true;
        this.phaseService.promotionApplied = true;
        this.phaseService.manualPromoCode =
          this.dataService.manualPromotion.code;
        this.phaseService.isPopupPromotionApplied = true;
        if (this.phaseService.isValidPromotion()) {
          this.phaseService.selectedHash.promotion_id =
            this.dataService.manualPromotion.id;
          this.phaseService.flatPrice =
            this.dataService.manualPromotion.discount;
          this.phaseService.discountedPrice =
            this.dataService.couponObject.discount;
          this.phaseService.updatePrice();
        } else {
          this.dataService.manualPromotion = null;
          this.phaseService.isPopupPromotionApplied = false;
          this.dataService.couponObject = null;
          this.phaseService.openSideNav = false;
        }
      }
    }
  }

  public isShowPromoBar() {
    return (
      this.phaseService.showPromobar &&
      (this.phaseService.clickedpopupsignin ||
        this.phaseService.popupCoupon ||
        this.phaseService.isAppDiscountApplied)
    );
  }

  public getCurrencies() {
    return this.phaseService.getCurrencies();
  }

  public setSelectedCurrency(currency) {
    this.phaseService.setSelectedCurrency(currency);
  }

  public isActiveCurrency(currency: CurrencyModel) {
    return this.phaseService.isActiveCurrency(currency);
  }

  public closeCurrencyChooserForUser() {
    this.dataService.showHideLoaderForBNow(true);
    this.phaseService.closeCurrencyChooserForUser();
  }

  public showPopUp() {
    this.phaseService.showCurrencyDropDown();
  }

  public hideOptionList() {
    this.phaseService.hideOptionList();
  }

  public getCurrency() {
    return this.phaseService.getCurrency();
  }

  public getCurrencyCode() {
    return this.phaseService.getCurrencyCode();
  }

  public isShowCurrencyDropdown() {
    return this.phaseService.isShowCurrencyDropdownForBNow();
  }

  public selectCurrency(currency) {
    this.selectedCurrency = currency;
    this.toggleCurrenctDropdown();
  }

  public isCurrentCurrency(currency: CurrencyModel) {
    return this.phaseService.isCurrentCurrency(currency);
  }

  public toggleSelectedAppsById(app: any): void {
    const selectedApp = this.phaseService.selectedApps.find(
      (x) => x.id === app.id
    );
    if (selectedApp) {
      const index = this.phaseService.selectedApps.indexOf(selectedApp);
      this.phaseService.selectedApps.splice(index, 1);
    } else {
      this.phaseService.selectedApps.push(app);
      if (
        this.dataService.buildCardData &&
        !this.dataService.buildCardData.id
      ) {
        if (
          this.dataService.user &&
          this.phaseService.selectedApps[0].pricing_modal === "rental_price"
        ) {
          this.dataService.homePageData.currency =
            this.dataService.user.currency;
        } else if (
          !this.dataService.user &&
          this.phaseService.selectedApps[0].pricing_modal === "rental_price"
        ) {
          this.dataService.homePageData.currency = app.currency;
        }
      }
    }
  }

  validateEmailOnFocusLeave() {
    if (this.isSocialSignClicked) {
      setTimeout(() => {
        this.isSocialSignClicked = false;
      }, 500);
      return;
    } else {
      if (this.checkIfEmailValid(false, true)) {
        this.checkPartnerEmail = true;
        return;
      } else {
        this.checkPartnerEmail = false;
      }
    }
  }

  validateNameOnFocusLeave() {
    if (this.checkFirstNameValid()) {
      this.checkNameValidOnClick = true;
      return;
    } else {
      this.checkNameValidOnClick = false;
    }
  }

  validatePhoneOnFocusLeave() {
    if (this.checkIfMobileValid()) {
      this.checkNumberValidOnClick = true;
      return;
    } else {
      this.checkNumberValidOnClick = false;
    }
  }

  validatePasswordOnFocusLeave() {
    if (this.isPasswordError()) {
      this.checkPwdValidOnClick = true;
      return;
    } else {
      this.checkPwdValidOnClick = false;
    }
  }

  validateTandCOnCheckboxChange() {
    if (!this.signupTerm2 && !this.phaseService.isPartnerDashboard()) {
      this.checkTandCValidOnClick = true;
      return;
    } else {
      this.checkTandCValidOnClick = false;
    }
  }

  validateEmailOnLoginOnFocusLeave() {
    if (this.isSocialSignClicked) {
      setTimeout(() => {
        this.isSocialSignClicked = false;
      }, 500);
      return;
    } else {
      this.shouldValidateEmail = true;
      if (this.checkIfEmailValid(true)) {
        this.checkValidEmailOnLogin = true;
        this.showEmailError = true;
        return;
      }
    }
  }

  validatePasswordOnLoginOnFocusLeave() {
    this.shouldValidatePassword = true;
    if (this.isPasswordError(true)) {
      this.checkValidPwdOnLogin = true;
      this.showPwdError = true;
      return;
    }
  }

  resetErrorMsgsOnSignupScreen() {
    this.checkPartnerEmail = false;
    this.checkNameValidOnClick = false;
    this.checkNumberValidOnClick = false;
    this.checkPwdValidOnClick = false;
    this.checkTandCValidOnClick = false;
  }

  resetErrorMsgsOnSignInScreen() {
    this.showEmailError = false;
    this.showPwdError = false;
  }

  emailFieldClicked() {
    this.isSocialSignClicked = false;
  }

  resetErrorMessage() {
    this.ErrorMsg = "";
  }

  checkIfEmailContainsErrorAfterSignup() {
    return this.ErrorMsg && this.ErrorMsg.includes("Email");
  }

  removeFocus() {
    this.isSocialSignClicked = true;
    document.getElementById("loginEmailInput").blur();
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(
      this.signup_data.phone_number.toString(),
      this.dataService.countryISOCode
    );
    if (phoneNumber) {
      return !(
        phoneNumber.isValid() &&
        this.signup_data.phone_number.includes(
          "+" + phoneNumber.countryCallingCode
        )
      );
    } else {
      return true;
    }
  }

  setUserContact(event) {
    this.signup_data.phone_number = event;
    this.socialSignup.phone_number = event;
  }

  openUrls(url) {
    window.open(url);
  }

  getUserDetailsFromToken() {
    if (
      this.appDataService.urlParameters.token &&
      !this.appDataService.urlParameters.is_freemium
    ) {
      const resp = this.dataService.userDataFromToken;
      if (resp) {
        this.signup_data.phone_number = resp.phone_number
          ? resp.phone_number
          : "";
        this.signup_data.email = resp.email ? resp.email : "";
        this.login_data.email = resp.email ? resp.email : "";
        this.signup_data.first_name = resp.first_name ? resp.first_name : "";
        this.signup_data.last_name = resp.last_name ? resp.last_name : "";
        this.fullName =
          this.signup_data.first_name + " " + this.signup_data.last_name;
        if (resp.user && resp.user.id) {
          this.signup_data.email = resp.user.email ? resp.user.email : "";
          this.login_data.email = resp.user.email ? resp.user.email : "";
        }
      }
    }
  }
}
